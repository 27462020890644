<template>
  <div>
    <!-- 查询 -->
    <el-card body-style="padding: 0" style="height: 5rem">
      <el-form ref="queryform" :model="queryform" label-width="80px">
        <el-row :gutter="30" style="padding: 20px 12px;">
          <el-col :span="6">
            <el-form-item label="角色名称" prop="name">
              <el-input
                v-model="queryform.name"
                placeholder="请输入角色名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="13">
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="submitQueryForm()"
                icon="el-icon-search"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="small"
                plain
                @click="resetQueryForm('queryform')"
                icon="el-icon-refresh"
              >
                重置
              </el-button>

              <el-button
                v-if="authFlag('post')"
                type="primary"
                size="small"
                style="margin: 0 0 10px 20px"
                icon="el-icon-plus"
                @click="handleAdd()"
                >新增
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <!-- 列表 -->
    <el-card>
      <el-table
        :data="tableData"
        row-key="id"
        border
        stripe
        style="width: 100%; margin-bottom: 20px"
      >
        <el-table-column
          prop="roleCode"
          label="角色编码"
          width="150"
          fixed="left"
          align="center"
        />
        <el-table-column
          prop="roleName"
          label="角色名称"
          width="150"
          align="center"
        />
        <el-table-column
          prop="roleDesc"
          label="角色描述"
          width="150"
          align="center"
        />
        <el-table-column
          prop="roleSort"
          label="角色排序"
          width="150"
          align="center"
        />

        <!-- 均有 -->
        <el-table-column
          prop="creatorName"
          label="创建者"
          width=""
          align="center"
        />
        <el-table-column
          prop="ustamp"
          label="最后更新时间"
          width="160"
          align="center"
        />
        <el-table-column prop="remark" label="备注" width="" align="center" />
        <!-- 操作 -->
        <el-table-column label="操作" width="250" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="authFlag('get')"
              size="mini"
              type="info"
              icon="el-icon-view"
              circle
              @click="handleInfo(scope.$index, scope.row, infoRouteName)"
            />
            <el-button
              v-if="authFlag('put')"
              size="mini"
              type="primary"
              icon="el-icon-edit"
              circle
              @click="handleEdit(scope.$index, scope.row, dialogTitle)"
            />
            <el-button
              v-if="authFlag('delete')"
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="handleDelete(scope.$index, scope.row)"
            />
            <el-button
              v-if="authFlag('assign')"
              size="mini"
              type="success"
              @click="assignPerm(scope.$index, scope.row)"
            >
              分配权限
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="this.pagination.pageNum"
        :page-size="this.pagination.pageSize"
        :total="this.pagination.total"
        @current-change="handlePageChange"
        style="float: right; margin-bottom: 10px"
      ></el-pagination>
    </el-card>

    <!-- 弹框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      :before-close="this.beforeDialogClose"
    >
      <el-form
        :model="dialogForm"
        ref="dialogForm"
        :rules="dialogFormRules"
        label-width="150px"
        style="padding-right: 70px; margin-left: -30px;"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="角色编码" prop="roleCode">
              <el-input
                v-model="dialogForm.roleCode"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色名称" prop="roleName">
              <el-input
                v-model="dialogForm.roleName"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="角色描述" prop="roleDesc">
              <el-input
                v-model="dialogForm.roleDesc"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色排序" prop="roleSort">
              <el-input-number
                v-model="dialogForm.roleSort"
                :min="1"
                label="排序序号"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 备注 -->
        <el-row :gutter="20" style="padding-right: 10px; margin-left: -1.6rem; margin-bottom: -20px;">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="dialogForm.remark" autocomplete="off"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="this.submitDialogForm" size="mini" type="primary"
          >确 定</el-button
        >
        <el-button @click="this.handleCloseDialog" size="mini">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 分配权限 框-->
    <el-dialog :title="permDialogTitle" :visible.sync="permDialogVisible">
      <el-dropdown @command="handleCommand" trigger="click">
        <el-button size="mini">
          选择方式<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="">联级选择</el-dropdown-item>
          <el-dropdown-item command="1">直接选择</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-row>
        <el-col :span="12">
          <multi-tree
            ref="tmp1"
            :data="menuData"
            :labelPropName="menuLabelPropName"
            :buttonName="menuButtonName"
            :defaultExpandedKeys="menuDefaultExpandedKeys"
            :defaultCheckedKeys="menuDefaultCheckedKeys"
            @handleCheckedKeys="handleMenuCheckedKeys"
          ></multi-tree>
        </el-col>
        <el-col :span="12">
          <multi-tree
            ref="tmp2"
            :data="servData"
            :labelPropName="servLabelPropName"
            :buttonName="servButtonName"
            :defaultExpandedKeys="servDefaultExpandedKeys"
            :defaultCheckedKeys="servDefaultCheckedKeys"
            @handleCheckedKeys="handleServCheckedKeys"
          ></multi-tree>

          <!--          <checkbox-panel-->
          <!--              :data="servData"-->
          <!--              :props="servProps"-->
          <!--              :buttonName="servButtonName"-->
          <!--              :defaultCheckedKeys="servDefaultCheckedKeys"-->
          <!--              @handleCheckedKeys="handleServCheckedKeys"-->
          <!--          ></checkbox-panel>-->
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import MixinCUD from "@/components/MixinCUD";
import Pagination from "@/components/Pagination";
import MultiTree from "../../components/MultiTree";
import { addDict, arrDifferenceDet } from "../../utils/utils";
import {
  addRole,
  deleteRole,
  getRoleList,
  updateRole,
} from "../../api/ca/ca_role";
import { Constants } from "../../lib/constants";
import axios from "axios";
import { getMenuTree } from "../../api/ca/ca_menu";
import { getMenuIdsbyRoleid, saveRoleMenu } from "../../api/ca/ca_role_menu";
import { Message } from "element-ui";
import { getServTree } from "../../api/ca/ca_serv";
import CheckboxPanel from "../../components/CheckboxPanel";
import { getServIdsbyRoleid, saveRoleServ } from "../../api/ca/ca_role_serv";
import { authFlag } from "../../store/modules/maintabs";

export default {
  name: "CaRoleList",
  mixins: [MixinCUD, Pagination],
  components: { MultiTree, CheckboxPanel },
  data() {
    return {
      // 列表数据
      tableData: [],
      queryFormRefName: "queryForm",
      // 查询表格
      queryform: {
        name: "",
        status: null,
      },
      // 详情页面路由名称
      infoRouteName: "CaRoleInfo",

      // 增删改弹框
      dialogFormVisible: false,
      dialogTitle: "",
      dialogRefName: "dialogForm",
      dialogForm: {
        id: null,
        roleCode: "",
        roleDesc: "",
        roleName: "",
        roleSort: "",
        remark: "",
      },
      dialogFormRules: {
        roleCode: [
          { required: true, message: "请选择角色编码", trigger: "blur" },
        ],
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
      },

      // 分配权限框
      handlingRoleId: null, //当前正为哪一个角色分配权限：roleId
      permDialogVisible: false,
      permDialogTitle: "",
      menuData: [], // 菜单树数据
      menuLabelPropName: "menuName",
      menuButtonName: "保存菜单查看权限",
      menuDefaultExpandedKeys: [],
      menuDefaultCheckedKeys: [],

      servData: [], // 服务树数据
      servLabelPropName: "servName",
      servButtonName: "保存服务访问权限",
      servDefaultExpandedKeys: [],
      servDefaultCheckedKeys: [],
    };
  },
  methods: {
    /**
     * 获取数据
     */
    getData() {
      getRoleList(addDict(this.queryForm, this.pagination)).then((res) => {
        this.setData(res);
      });
    },

    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.tableData = res.data;
        this.pagination.pageSize = res.pageSize;
        this.pagination.total = res.total;
      }
    },

    /**
     * 新增数据
     */
    addData() {
      addRole(this.dialogForm).then((res) => {
        this.afterDateRes(res);
      });
    },

    /**
     * 修改数据
     */
    updateData() {
      updateRole(this.dialogForm).then((res) => {
        this.afterDateRes(res);
      });
    },

    /**
     * 删除数据
     * @param row
     */
    deleteData(row) {
      deleteRole({ ids: row.id })
        .then((res) => {
          this.afterDateRes(res);
        })
        .catch((err) => {
          console.error(err.message);
        });
    },

    /**
     * 权限认证
     */
    authFlag(flag) {
      return authFlag(this.$route.name + ":" + flag);
    },

    /**
     * 分配权限
     * @param index
     * @param row
     */
    assignPerm(index, row) {
      this.handlingRoleId = row.id;
      let _this = this;
      // , getApiCheckedTree(row.id)
      axios
        .all([
          getMenuTree(),
          getMenuIdsbyRoleid({ roleId: row.id }),
          getServTree(),
          getServIdsbyRoleid({ roleId: row.id }),
        ])
        .then(
          axios.spread(function (res1, res2, res3, res4) {
            // 两个请求都执行完成后，进入该函数
            if (res1.code === "0") {
              // 菜单树
              _this.menuData = res1.data;
            }

            if (res2.code === "0") {
              // 选中菜单
              _this.menuDefaultCheckedKeys = res2.data;
            }

            if (res3.code === "0") {
              // 服务列表
              _this.servData = res3.data;
            }

            if (res4.code === "0") {
              // 选中服务
              _this.servDefaultCheckedKeys = res4.data;
            }

            // 显示弹框
            _this.permDialogVisible = true;
            _this.permDialogTitle = row.roleName + "角色:权限分配";
          })
        );
    },

    /**
     * 保存选中菜单
     * @param checkedKeys
     */
    handleMenuCheckedKeys(checkedKeys) {
      let delMenuIds = arrDifferenceDet(
        this.menuDefaultCheckedKeys,
        checkedKeys
      );
      let addMenuIds = arrDifferenceDet(
        checkedKeys,
        this.menuDefaultCheckedKeys
      );
      saveRoleMenu({
        roleId: this.handlingRoleId,
        addMenuIds: addMenuIds,
        delMenuIds: delMenuIds,
      }).then((res) => {
        if (res.code === "0") {
          Message.success(res.message);
          this.menuDefaultCheckedKeys = checkedKeys;
        } else {
          Message.error(res.message);
          console.error(res);
        }
      });
    },

    handleCommand(command) {
      this.$refs.tmp1.checkStrictly = Boolean(command);
      this.$refs.tmp2.checkStrictly = Boolean(command);
    },

    /**
     * 保存选中服务
     * @param checkedKeys
     */
    handleServCheckedKeys(checkedKeys) {
      let delServIds = arrDifferenceDet(
        this.servDefaultCheckedKeys,
        checkedKeys
      );
      let addServIds = arrDifferenceDet(
        checkedKeys,
        this.servDefaultCheckedKeys
      );
      saveRoleServ({
        roleId: this.handlingRoleId,
        addServIds: addServIds,
        delServIds: delServIds,
      }).then((res) => {
        if (res.code === "0") {
          Message.success(res.message);
          this.servDefaultCheckedKeys = checkedKeys;
        } else {
          Message.error(res.message);
          console.error(res);
        }
      });
    },
  },

  beforeRouteEnter(to, from, next) {
    getRoleList({
      pageNum: Constants.pageNum,
      pageSize: Constants.pageSize,
    }).then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>

<style scoped>
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
