import http from "@/utils/http";

//=====================服务接口========================


/**
 * 获取列表
 * @param data
 * @returns {*}
 */
export const getCMList = (data) => {
    return http({
        url: '/api/fhh/v2/community/list',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

/**
 * 获取详情
 * @param data
 * @returns {*}
 */
export const getCM = (data) => {
    return http({
        url: '/api/fhh/v2/community',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

/**
 * 更新的一条数据记录
 * @param data
 * @returns {*}
 */
 export const updateCM = (data) => {
    return http({
        url: '/api/fhh/v2/community',
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

/**
 * 新增一条数据记录
 * @param data
 * @returns {*}
 */
export const addCM = (data) => {
    return http({
        url: '/api/fhh/v2/community',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

/**
 * 删除一条数据记录
 * @param data
 * @returns {*}
 */
export const deleteCM = (data) => {
    return http({
        url: '/api/fhh/v2/community',
        method: 'delete',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}
