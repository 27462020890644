<template>
  <div>
    <!-- 查询 -->
    <el-card body-style="padding: 0" style="height: 5rem">
      <el-form ref="queryform" :model="queryform" label-width="80px">
        <el-row :gutter="30" style="padding: 20px 12px">
          <el-col :span="6">
            <el-form-item label="组织名称" prop="name">
              <el-input
                v-model="queryform.name"
                placeholder="请输入组织名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="13">
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="submitQueryForm()"
                icon="el-icon-search"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="small"
                plain
                @click="resetQueryForm('queryform')"
                icon="el-icon-refresh"
              >
                重置
              </el-button>
              <el-button
                type="primary"
                size="small"
                style="margin: 0 0 10px 20px"
                icon="el-icon-plus"
                @click="handleAdd()"
                >新增
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <!-- 列表 -->
    <el-card>
      <el-table
        :data="tableData"
        row-key="id"
        border
        stripe
        default-expand-all
        style="width: 100%; margin-bottom: 20px"
      >
        <el-table-column
          prop="orgCode"
          label="组织编码"
          width="200"
          fixed="left"
        />
        <el-table-column
          prop="orgName"
          label="组织名称"
          width="150"
          align="center"
        />
        <el-table-column
          prop="orgSort"
          label="组织排序"
          width="150"
          align="center"
        />

        <!-- 均有 -->
        <el-table-column
          prop="creatorName"
          label="创建者"
          width=""
          align="center"
        />
        <el-table-column
          prop="ustamp"
          label="最后更新时间"
          width=""
          align="center"
        />
        <el-table-column prop="remark" label="备注" width="" align="center" />
        <!-- 操作 -->
        <el-table-column label="操作" width="150" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="authFlag('get')"
              size="mini"
              type="info"
              icon="el-icon-view"
              circle
              @click="handleInfo(scope.$index, scope.row, infoRouteName)"
            />
            <el-button
              v-if="authFlag('put')"
              size="mini"
              type="primary"
              icon="el-icon-edit"
              circle
              @click="handleEdit(scope.$index, scope.row, dialogTitle)"
            />
            <el-button
              v-if="authFlag('delete')"
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="handleDelete(scope.$index, scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 弹框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      :before-close="this.beforeDialogClose"
    >
      <el-form
        :model="dialogForm"
        ref="dialogForm"
        :rules="dialogFormRules"
        label-width="150px"
        style="padding-right: 80px"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="上级菜单" prop="orgPid">
              <el-tree-select
                :elTreeProps="elTreeProps"
                :elTreeData="orgTree"
                :defaultSelectedId="dialogForm.orgPid"
                :disabled="elTreeDisabled"
                @handleTreeSelected="handleTreeSelected($event)"
                @validateSelectTree="validateSelectTree"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="组织编码" prop="orgCode">
              <el-input
                v-model="dialogForm.orgCode"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="组织名称" prop="orgName">
              <el-input
                v-model="dialogForm.orgName"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="组织排序" prop="orgSort">
              <el-input-number
                v-model="dialogForm.orgSort"
                :min="1"
                label="排序序号"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 备注 -->
        <el-row :gutter="20" style="padding-right: 10px; margin-left: -1.6rem;">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="dialogForm.remark" autocomplete="off"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="this.submitDialogForm" size="mini" type="primary"
          >确 定</el-button
        >
        <el-button @click="this.handleCloseDialog" size="mini">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MixinCUD from "@/components/MixinCUD";
import ElTreeSelect from "@/components/TreeSelect";
import Pagination from "@/components/Pagination";
import { addOrg, deleteOrg, getOrgTree, updateOrg } from "../../api/ca/ca_org";

import { authFlag } from "../../store/modules/maintabs";
export default {
  name: "CaOrgList",
  mixins: [MixinCUD, Pagination],
  components: { ElTreeSelect },
  data() {
    return {
      // 列表数据
      tableData: [],
      queryFormRefName: "queryForm",
      // 查询表格
      queryform: {
        name: "",
        status: null,
      },
      // 详情页面路由名称
      infoRouteName: "CaOrgInfo",

      // 树
      orgTree: [], //带root节点,用于新增修改
      elTreeDisabled: false,
      elTreeProps: {
        // el-tree-select配置项（必选）
        value: "id",
        label: "orgName",
        children: "children",
      },

      // 增删改弹框
      dialogFormVisible: false,
      dialogTitle: "",
      dialogRefName: "dialogForm",
      dialogForm: {
        id: null,
        orgCode: "",
        orgPid: "",
        orgName: "",
        orgSort: "",
        remark: "",
      },
      dialogFormRules: {
        orgCode: [
          { required: true, message: "请选择组织编码", trigger: "blur" },
        ],
        orgName: [
          { required: true, message: "请输入组织名称", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    /**
     * 权限认证
     */
    authFlag(flag) {
      return authFlag(this.$route.name + ":" + flag);
    },

    /**
     * 获取数据
     */
    getData() {
      getOrgTree().then((res) => {
        _this.setData(res);
      });
    },

    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.tableData = res.data;
        this.orgTree = res.data;
      }
    },

    /**
     * 新增数据
     */
    addData() {
      addOrg(this.dialogForm).then((res) => {
        this.afterDateRes(res);
      });
    },

    /**
     * 修改数据
     */
    updateData() {
      updateOrg(this.dialogForm).then((res) => {
        this.afterDateRes(res);
      });
    },

    /**
     * 删除数据
     * @param row
     */
    deleteData(row) {
      deleteOrg({ ids: row.id })
        .then((res) => {
          this.afterDateRes(res);
        })
        .catch((err) => {
          console.error(err.message);
        });
    },

    handleTreeSelected(value) {
      this.dialogForm.orgPid = value;
      this.$refs.dialogForm.validateField("orgPid");
    },
    validateSelectTree() {
      this.$refs.dialogForm.validateField("orgPid");
    },
  },

  beforeRouteEnter(to, from, next) {
    getOrgTree().then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>
