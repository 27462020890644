<template>
  <el-form ref="formRef" :model="form" class="body">
    <el-descriptions
      :column="2"
      :size="size"
      border
      style="font-size: 1.1rem; padding: 20px 30px"
      :labelStyle="{ 'text-align': 'center' }"
      :contentStyle="{ 'text-align': 'center', 'max-width': '300px' }"
    >
      <el-descriptions-item label="角色编码">
        {{ form.roleCode }}
      </el-descriptions-item>
      <el-descriptions-item label="角色名称">{{
        form.roleName
      }}</el-descriptions-item>
      <el-descriptions-item label="角色描述">{{
        form.roleDesc
      }}</el-descriptions-item>
      <el-descriptions-item label="角色排序">{{
        form.roleSort
      }}</el-descriptions-item>
      <el-descriptions-item label="创建者">{{
        form.creatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{
        form.cstamp
      }}</el-descriptions-item>
      <el-descriptions-item label="修改者">{{
        form.updatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="修改时间">{{
        form.ustamp
      }}</el-descriptions-item>
      <el-descriptions-item label="备注">{{
        form.remark
      }}</el-descriptions-item>
    </el-descriptions>
  </el-form>
</template>

<script>
import { getRole } from "../../api/ca/ca_role";

export default {
  name: "CaRoleInfo",
  data() {
    return {
      formRef: null,
      form: {
        id: "",
        creatorId: "",
        creatorName: "",
        updatorId: "",
        updatorName: "",
        deleted: "",
        remark: "",
        cstamp: "",
        ustamp: "",
        roleCode: "",
        roleDesc: "",
        roleName: "",
        roleSort: "",
      },
    };
  },
  created() {
    // if (this.$route.query.type === "insert" || this.$route.query.type === "update") {
    //   this.isNoEdit = false;
    // }
  },
  methods: {
    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.form = res.data;
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    getRole({
      id: to.query.id,
    }).then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>
