<template>
  <div>
    <el-card>
      <div style="margin-left: 20px">
        <el-button type="primary" size="mini" @click="handleCheckedKeys">
          {{ buttonName }}
        </el-button>

        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <el-checkbox-group v-model="checked">
          <el-checkbox
              v-for="item in data"
              :key="item[props.key]"
              :label="item[props.key]">
            {{ item[props.label] }}
          </el-checkbox>
        </el-checkbox-group>

      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "CheckboxPanel",
  props: {
    buttonName: {type: String, default: "获取"},
    data: {type: Array, default: () => []},
    labelPropName: {type: String, default: ""},
    defaultCheckedKeys: {type: Array, default: () => []},
    props: {
      type: Object,
      default() {
        return {
          label: 'label',
          key: 'key',
          disabled: 'disabled'
        };
      }
    },
  },
  data() {
    return {
      checked: this.defaultCheckedKeys, // 选中值
      checkAll: false, // 是否为全选状态
      isIndeterminate: true // 部分选中时
    };
  },
  methods: {
    /**
     * 执行button，将数据返回上一层
     */
    handleCheckedKeys() {
      this.$emit('handleCheckedKeys', this.checked)
    },

    /**
     * 全选/取消全选
     */
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.data : [];
      this.isIndeterminate = false;
    },

    /**
     * 改变一个选项状态时
     * @param value
     */
    handleOneKeys(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.data.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    }

  }

}
</script>

<style scoped>

</style>
