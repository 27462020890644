import http from "@/utils/http";

//=====================接口========================

/**
 * 获取组织树
 * @param data
 * @returns {*}
 */
export const getOrgTree = (data) => {
    return http({
        url: '/api/ca/v2/org/tree',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

/**
 * 获取列表
 * @param data
 * @returns {*}
 */
export const getOrgList = (data) => {
    return http({
        url: '/api/ca/v2/org/list',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

/**
 * 获取详情
 * @param data
 * @returns {*}
 */
export const getOrg = (data) => {
    return http({
        url: '/api/ca/v2/org',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

/**
 * 更新的一条数据记录
 * @param data
 * @returns {*}
 */
export const updateOrg = (data) => {
    return http({
        url: '/api/ca/v2/org',
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

/**
 * 新增一条数据记录
 * @param data
 * @returns {*}
 */
export const addOrg = (data) => {
    return http({
        url: '/api/ca/v2/org',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

/**
 * 删除一条数据记录
 * @param data
 * @returns {*}
 */
export const deleteOrg = (data) => {
    return http({
        url: '/api/ca/v2/org',
        method: 'delete',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

