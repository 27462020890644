<template>
  <el-form ref="formRef" :model="form" class="body">
    <el-descriptions
      :column="2"
      :size="size"
      border
      style="font-size: 1.1rem; padding: 20px 30px;"
      :labelStyle="{ 'text-align': 'center' }"
      :contentStyle="{ 'text-align': 'center', 'max-width': '300px' }"
    >
      <el-descriptions-item label="用户名">
        {{ form.username }}
      </el-descriptions-item>
      <el-descriptions-item label="代理">{{ form.agent }}</el-descriptions-item>
      <el-descriptions-item label="Ip">
        {{ form.authIp }}
      </el-descriptions-item>
      <el-descriptions-item label="模式">{{
        form.authModel
      }}</el-descriptions-item>
      <el-descriptions-item label="地址">
        {{ form.authUrl }}
      </el-descriptions-item>
      <el-descriptions-item label="浏览器信息">{{
        form.authAgent
      }}</el-descriptions-item>
      <el-descriptions-item label="请求开始时间">
        {{ form.requestStartTime }}
      </el-descriptions-item>

      <el-descriptions-item label="创建者">{{
        form.creatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{
        form.cstamp
      }}</el-descriptions-item>
      <el-descriptions-item label="修改者">{{
        form.updatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="修改时间">{{
        form.ustamp
      }}</el-descriptions-item>
      <el-descriptions-item label="备注">{{
        form.remark
      }}</el-descriptions-item>
    </el-descriptions>
  </el-form>
</template>

<script>
import { getAudit } from "../../api/ca/ca_audit";

export default {
  name: "CaAuditInfo",
  data() {
    return {
      formRef: null,
      form: {
        id: "",
        creatorId: "",
        creatorName: "",
        updatorId: "",
        updatorName: "",
        deleted: "",
        remark: "",
        cstamp: "",
        ustamp: "",

        username: "",
        agent: "",
        authIp: "",
        authModel: "",
        authUrl: "",
        authAgent: "",
        requestStartTime: "",
      },
    };
  },
  created() {
    // if (this.$route.query.type === "insert" || this.$route.query.type === "update") {
    //   this.isNoEdit = false;
    // }
  },
  methods: {
    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.form = res.data;
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    getAudit({
      id: to.query.id,
    }).then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>
