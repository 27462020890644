<template>
  <el-row>
    <el-col class="count-to-con" style="margin-top: 5px">
      <count-to
        :delay="300"
        :simplify="true"
        :end="end"
        count-class="count-text"
        unit-class="unit-class"
      >
      <template v-slot:left class="prefix-style"></template>
      <template v-slot:right></template>
      </count-to>
    </el-col>
  </el-row>
</template>

<script>
import CountTo from "./CountTo.vue";
export default {
  name: "CountToPage",
  components: {
    CountTo,
  },
  props: {
    end: {
      type: Number,
      default: 0
    }
  },
};
</script>

<style lang="less">
@baseColor: ~"#dc9387";
.countto-page-row {
  height: 200px;
}
.count-to-con {
  display: block;
  width: 100%;
  text-align: center;
}
.count-text {
  font-size: 50px;
  color: @baseColor;
}
.slot-text {
  font-size: 22px;
}
.unit-class {
  font-size: 30px;
  color: @baseColor;
}
</style>
