<template>
  <el-form ref="formRef" :model="form" class="body">
    <el-descriptions
      :column="2"
      border
      style="font-size: 1.1rem; padding: 20px 30px"
      :labelStyle="{ 'text-align': 'center', width: '350px' }"
      :contentStyle="{ 'text-align': 'center', width: '420px' }"
    >
      <el-descriptions-item label="id">{{ form.id }}</el-descriptions-item>
      <el-descriptions-item label="地址">
        {{ form.address }}
      </el-descriptions-item>
      <el-descriptions-item label="名称">{{ form.name }}</el-descriptions-item>
      <el-descriptions-item label="服务端名称">{{
        form.clientName
      }}</el-descriptions-item>
      <el-descriptions-item label="创建者">
        {{ form.creatorId }}
      </el-descriptions-item>
      <el-descriptions-item label="创建者名字">{{
        form.creatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="修改者">
        {{ form.updatorId }}
      </el-descriptions-item>
      <el-descriptions-item label="修改者名字">{{
        form.updatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="创建时间戳">{{
        form.cstamp
      }}</el-descriptions-item>
      <el-descriptions-item label="修改时间戳">{{
        form.ustamp
      }}</el-descriptions-item>

      <el-descriptions-item label="是否软删">
        {{ form.deleted }}
      </el-descriptions-item>
      <el-descriptions-item label="端口号">{{
        form.port
      }}</el-descriptions-item>
      <el-descriptions-item label="机器ip">{{ form.ip }}</el-descriptions-item>
      <el-descriptions-item label="nacos注册ip">{{
        form.nacosIp
      }}</el-descriptions-item>
      <el-descriptions-item label="注释">{{
        form.remark
      }}</el-descriptions-item>
    </el-descriptions>
  </el-form>
</template>

<script>
import { getCM } from "../../api/fhs/fhs_cm";

export default {
  name: "FhsCMInfo",
  data() {
    return {
      formRef: null,
      form: {
        address: "",
        clientName: "",
        creatorId: "",
        creatorName: "",

        id: "",
        deleted: "",
        ip: "",
        cstamp: "",
        ustamp: "",

        nacosIp: "",
        name: "",
        port: "",
        updatorId: "",
        updatorName: "",
        remark: "",
      },
    };
  },
  created() {
    // if (this.$route.query.type === "insert" || this.$route.query.type === "update") {
    //   this.isNoEdit = false;
    // }
  },
  methods: {
    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.form = res.data;
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    getCM({
      id: to.query.id,
    }).then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>
