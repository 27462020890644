<template>
  <el-form ref="formRef" :model="form" class="body">
    <el-descriptions
      :column="2"
      :size="size"
      border
      style="font-size: 1.1rem; padding: 20px 30px"
      :labelStyle="{ 'text-align': 'center', 'width': '200px'  }"
      :contentStyle="{ 'text-align': 'center', 'width': '350px'  }"
    >
      <el-descriptions-item label="服务编码">
        {{ form.servCode }}
      </el-descriptions-item>
      <el-descriptions-item label="服务名称">{{
        form.servName
      }}</el-descriptions-item>
      <el-descriptions-item label="服务排序">
        {{ form.servSort }}
      </el-descriptions-item>
      <el-descriptions-item label="服务地址">{{
        form.url
      }}</el-descriptions-item>
      <el-descriptions-item label="服务方法">
        {{ form.method }}
      </el-descriptions-item>
      <el-descriptions-item label="节点类型">{{
        form.type
      }}</el-descriptions-item>

      <el-descriptions-item label="创建者">{{
        form.creatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{
        form.cstamp
      }}</el-descriptions-item>
      <el-descriptions-item label="修改者">{{
        form.updatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="修改时间">{{
        form.ustamp
      }}</el-descriptions-item>
      <el-descriptions-item label="备注">{{
        form.remark
      }}</el-descriptions-item>
    </el-descriptions>
  </el-form>
</template>

<script>
import { getServ } from "../../api/ca/ca_serv";

export default {
  name: "CaServInfo",
  data() {
    return {
      formRef: null,
      form: {
        id: "",
        creatorId: "",
        creatorName: "",
        updatorId: "",
        updatorName: "",
        deleted: "",
        remark: "",
        cstamp: "",
        ustamp: "",
        code: "",
        name: "",
        url: "",
        type: "",
      },
    };
  },
  created() {
    // if (this.$route.query.type === "insert" || this.$route.query.type === "update") {
    //   this.isNoEdit = false;
    // }
  },
  methods: {
    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.form = res.data;
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    getServ({
      id: to.query.id,
    }).then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>
