<template>
  <div>
    <!-- 查询 -->
    <el-card body-style="padding: 0" style="height: 5rem">
      <el-form ref="queryform" :model="queryform" label-width="80px">
        <el-row :gutter="30" style="padding: 20px 12px;">
          <el-col :span="6">
            <el-form-item label="服务名称" prop="name">
              <el-input
                v-model="queryform.name"
                placeholder="请输入服务名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="13">
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="submitQueryForm()"
                icon="el-icon-search"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="small"
                plain
                @click="resetQueryForm('queryform')"
                icon="el-icon-refresh"
              >
                重置
              </el-button>

              <el-button
                type="primary"
                size="small"
                style="margin: 0 0 10px 20px"
                icon="el-icon-plus"
                @click="handleAdd()"
                >新增
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <!-- 列表 -->
    <el-card>
      <el-table
        :data="tableData"
        row-key="id"
        border
        stripe
        style="width: 100%;margin-bottom: 20px;"
        default-expand-all
      >
        <el-table-column
          prop="servCode"
          label="服务编码"
          width="150"
          fixed="left"
        />
        <el-table-column
          prop="servName"
          label="服务名称"
          width="150"
          align="center"
        />
        <el-table-column
          prop="servSort"
          label="服务排序"
          width="100"
          align="center"
        />
        <el-table-column
          prop="url"
          label="服务地址"
          width="200"
          align="center"
        />
        <el-table-column
          prop="method"
          label="服务方法"
          width="100"
          align="center"
        />
        <el-table-column
          prop="type"
          label="节点类型"
          width="100"
          align="center"
        />

        <!-- 均有 -->
        <el-table-column
          prop="creatorName"
          label="创建者"
          width=""
          align="center"
        />
        <el-table-column
          prop="ustamp"
          label="最后更新时间"
          width=""
          align="center"
        />
        <el-table-column prop="remark" label="备注" width="" align="center" />
        <!-- 操作 -->
        <el-table-column label="操作" width="150" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="authFlag('get')"
              size="mini"
              type="info"
              icon="el-icon-view"
              circle
              @click="handleInfo(scope.$index, scope.row, infoRouteName)"
            />
            <el-button
              v-if="authFlag('put')"
              size="mini"
              type="primary"
              icon="el-icon-edit"
              circle
              @click="handleEdit(scope.$index, scope.row, dialogTitle)"
            />
            <el-button
              v-if="authFlag('delete')"
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="handleDelete(scope.$index, scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>

      <!--      &lt;!&ndash; 分页 &ndash;&gt;-->
      <!--      <el-pagination background layout="total, prev, pager, next"-->
      <!--                     :current-page="this.pagination.pageNum"-->
      <!--                     :page-size="this.pagination.pageSize"-->
      <!--                     :total="this.pagination.total"-->
      <!--                     @current-change="handlePageChange"-->
      <!--                     style="float: right;margin-bottom: 10px"></el-pagination>-->
    </el-card>

    <!-- 弹框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      :before-close="this.beforeDialogClose"
    >
      <el-form
        :model="dialogForm"
        ref="dialogForm"
        :rules="dialogFormRules"
        label-width="150px"
        style="padding-right: 80px"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="上级菜单" prop="servPid">
              <el-tree-select
                :elTreeProps="elTreeProps"
                :elTreeData="servTree"
                :defaultSelectedId="dialogForm.servPid"
                :disabled="elTreeDisabled"
                @handleTreeSelected="handleTreeSelected($event)"
                @validateSelectTree="validateSelectTree"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="服务编码" prop="servCode">
              <el-input
                v-model="dialogForm.servCode"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服务名称" prop="servName">
              <el-input
                v-model="dialogForm.servName"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="服务地址" prop="url">
              <el-input v-model="dialogForm.url" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服务方法" prop="type">
              <el-select v-model="dialogForm.method">
                <el-option
                  v-for="(item, index) in servMethodLabel"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="服务排序" prop="servSort">
              <el-input-number
                v-model="dialogForm.servSort"
                :min="1"
                label="排序序号"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="节点类型" prop="type">
              <el-select v-model="dialogForm.type">
                <el-option
                  v-for="(item, index) in servTypeLabel"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 备注 -->
        <el-row :gutter="20" style="padding-right: 10px; margin-left: -1.6rem;">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="dialogForm.remark" autocomplete="off"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="this.submitDialogForm" size="mini" type="primary"
          >确 定</el-button
        >
        <el-button @click="this.handleCloseDialog" size="mini">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MixinCUD from "@/components/MixinCUD";
import ElTreeSelect from "@/components/TreeSelect";
import Pagination from "@/components/Pagination";
import {
  addServ,
  deleteServ,
  getServTree,
  updateServ,
} from "../../api/ca/ca_serv";
import { authFlag } from "../../store/modules/maintabs";

export default {
  name: "CaServList",
  mixins: [MixinCUD, Pagination],
  components: { ElTreeSelect },
  data() {
    return {
      // 列表数据
      tableData: [],
      queryFormRefName: "queryForm",
      // 查询表格
      queryform: {
        name: "",
        status: null,
      },
      // 详情页面路由名称
      infoRouteName: "CaServInfo",

      // 树
      servTree: [], //带root节点,用于新增修改
      elTreeDisabled: false,
      elTreeProps: {
        // el-tree-select配置项（必选）
        value: "id",
        label: "servName",
        children: "children",
      },

      // 增删改弹框
      dialogFormVisible: false,
      dialogTitle: "",
      dialogRefName: "dialogForm",
      dialogForm: {
        id: null,
        servCode: "",
        servPid: "",
        servName: "",
        servSort: "",
        url: "",
        method: "",
        type: "",
        remark: "",
      },
      dialogFormRules: {
        servCode: [
          { required: true, message: "请选择服务编码", trigger: "blur" },
        ],
        servName: [
          { required: true, message: "请输入服务名称", trigger: "blur" },
        ],
        url: [{ required: true, message: "请输入服务地址", trigger: "blur" }],
        method: [
          { required: true, message: "请输入服务方法", trigger: "blur" },
        ],
        type: [{ required: true, message: "请输入节点类型", trigger: "blur" }],
      },

      // -------------------------------
      servMethodLabel: [
        {
          value: "GET",
          label: "GET",
        },
        {
          value: "POST",
          label: "POST",
        },
        {
          value: "PUT",
          label: "PUT",
        },
        {
          value: "DELETE",
          label: "DELETE",
        },
      ],
      servTypeLabel: [
        {
          value: "FATHER",
          label: "父节点",
        },
        {
          value: "CHILD",
          label: "子节点",
        },
      ],
    };
  },
  methods: {
    authFlag(flag) {
      return authFlag(this.$route.name + ":" + flag);
    },

    /**
     * 获取数据
     */
    getData() {
      getServTree().then((res) => {
        _this.setData(res);
      });

      // getServList(addDict(this.queryForm, this.pagination))
      //     .then(res => {
      //       this.setData(res)
      //     })
    },

    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.tableData = res.data;
        this.servTree = res.data;
        // this.pagination.pageSize = res.pageSize;
        // this.pagination.total = res.total;
      }
    },

    /**
     * 新增数据
     */
    addData() {
      addServ(this.dialogForm).then((res) => {
        this.afterDateRes(res);
      });
    },

    /**
     * 修改数据
     */
    updateData() {
      updateServ(this.dialogForm).then((res) => {
        this.afterDateRes(res);
      });
    },

    /**
     * 删除数据
     * @param row
     */
    deleteData(row) {
      deleteServ({ ids: row.id })
        .then((res) => {
          this.afterDateRes(res);
        })
        .catch((err) => {
          console.error(err.message);
        });
    },

    handleTreeSelected(value) {
      this.dialogForm.servPid = value;
      this.$refs.dialogForm.validateField("servPid");
    },
    validateSelectTree() {
      this.$refs.dialogForm.validateField("servPid");
    },
  },

  beforeRouteEnter(to, from, next) {
    getServTree().then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>
