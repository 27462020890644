<template>
  <el-form ref="formRef" :model="form" label-width="150px">


    <el-form-item label="登录用户" prop="username">
      {{ form.username }}
    </el-form-item>

    <el-form-item label="登录时间" prop="loginTime">
      {{ form.loginTime }}
    </el-form-item>

    <el-form-item label="远程Ip" prop="remoteIp">
      {{ form.remoteIp }}
    </el-form-item>

    <el-form-item label="浏览器信息" prop="agent">
      {{ form.agent }}
    </el-form-item>


    <el-form-item label="创建者" prop="creatorName">
      {{ form.creatorName }}
    </el-form-item>
    <el-form-item label="创建时间" prop="cstamp">
      {{ form.cstamp }}
    </el-form-item>
    <el-form-item label="修改者" prop="updatorName">
      {{ form.updatorName }}
    </el-form-item>
    <el-form-item label="修改时间" prop="ustamp">
      {{ form.ustamp }}
    </el-form-item>
    <el-form-item label="备注" prop="remark">
      {{ form.remark }}
    </el-form-item>

  </el-form>
</template>

<script>
import {getLogin} from "../../api/ca/ca_login_n";

export default {
  name: "CaLoginInfo",
  data() {
    return {
      formRef: null,
      form: {
        id: "",
        creatorId: "",
        creatorName: "",
        updatorId: "",
        updatorName: "",
        deleted: "",
        remark: "",
        cstamp: "",
        ustamp: "",

        username: "",
        loginTime: "",
        remoteIp: "",
        agent: ""
      }
    }
  },
  created() {
    // if (this.$route.query.type === "insert" || this.$route.query.type === "update") {
    //   this.isNoEdit = false;
    // }
  },
  methods: {
    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === '0') {
        this.form = res.data;
      }
    },
  },


  beforeRouteEnter(to, from, next) {
    getLogin({
      id: to.query.id
    }).then(res => {
      next(vm => vm.setData(res))
    })
  }
};
</script>

