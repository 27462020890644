<template>

</template>
<script>
// 分页通用,数据结构及函数
import {Constants} from "../lib/constants";

export default {
  name: "pagination",
  data() {
    return {
      pagination: {
        pageNum: Constants.pageNum,
        pageSize: Constants.pageSize,
        total: null
      },
    }
  },
  methods: {
    // handlePageSizeChange(val) {
    //   this.pagination.pageSize = val;
    //   this.submitQueryForm()
    // },
    // handlePageNumChange(val) {
    //   this.pagination.pageNum = val;
    //   this.submitQueryForm()
    // },

    handlePageChange(val) {
      this.pagination.pageNum = val;
      this.submitQueryForm();
    }
  }
}
</script>
<style scoped>
</style>
