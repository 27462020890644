<template>
  <div>
    <!-- 查询 -->
    <el-card body-style="padding: 0" style="height: 5rem">
      <el-form ref="queryform" :model="queryform" label-width="80px">
        <el-row :gutter="30" style="padding: 20px 12px">
          <el-col :span="6">
            <el-form-item label="用户名称" prop="name">
              <el-input
                v-model="queryform.name"
                placeholder="请输入用户名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="13">
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="submitQueryForm()"
                icon="el-icon-search"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="small"
                plain
                @click="resetQueryForm('queryform')"
                icon="el-icon-refresh"
              >
                重置
              </el-button>

              <el-button
                type="primary"
                size="small"
                style="margin: 0 0 10px 20px"
                icon="el-icon-plus"
                @click="handleAdd()"
                >新增
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <el-row>
      <el-col :span="4">
        <el-card
          style="
            height: 41.2rem;
            overflow-y: auto;
            overflow-x: hidden;
            background-color: rgb(251, 251, 253); ;
          "
          shadow
        >
          <el-tree
            :data="orgTree"
            :props="elTreeProps"
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            icon-class="el-icon-folder-opened"
            ref="tree"
            @node-click="handleNodeClick"
            default-expand-all
            highlight-current
          />

          <!-- <el-tree :data="data" node-key="id" :props="defaultProps">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>
                <i :class="data.XXX"></i>{{ node.label }}
              </span>
            </span>
          </el-tree> -->
        </el-card>
      </el-col>
      <!-- 列表 -->
      <el-col :span="20">
        <el-table
          :data="tableData"
          row-key="id"
          border
          stripe
          style="width: 100%; margin-bottom: 20px"
        >
          <el-table-column
            prop="userName"
            label="用户名"
            width="130"
            fixed="left"
            align="center"
          />
          <el-table-column
            prop="loginName"
            label="登录名"
            width="130"
            align="center"
          />
          <el-table-column
            prop="loginTime"
            label="登录时间"
            width="150"
            align="center"
          />

          <!-- 均有 -->
          <el-table-column
            prop="creatorName"
            label="创建者"
            width=""
            align="center"
          />
          <el-table-column
            prop="ustamp"
            label="最后更新时间"
            width="160"
            align="center"
          />
          <el-table-column prop="remark" label="备注" width="" align="center" />
          <!-- 操作 -->
          <el-table-column label="操作" width="320" align="center">
            <template slot-scope="scope">
              <el-button
                v-if="authFlag('get')"
                size="mini"
                type="info"
                icon="el-icon-view"
                circle
                @click="handleInfo(scope.$index, scope.row, infoRouteName)"
              />
              <el-button
                v-if="authFlag('put')"
                size="mini"
                type="primary"
                icon="el-icon-edit"
                circle
                @click="handleEdit(scope.$index, scope.row, dialogTitle)"
              />
              <el-button
                v-if="authFlag('delete')"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                circle
                @click="handleDelete(scope.$index, scope.row)"
              />
              <el-button
                v-if="authFlag('assign')"
                size="mini"
                type="success"
                @click="assignRole(scope.$index, scope.row)"
              >
                分配角色
              </el-button>
              <el-button
                v-if="authFlag('org')"
                size="mini"
                type="success"
                @click="assignOrg(scope.$index, scope.row)"
              >
                加入组织
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="this.pagination.pageNum"
          :page-size="this.pagination.pageSize"
          :total="this.pagination.total"
          @current-change="handlePageChange"
          style="float: right; margin-bottom: 10px"
        ></el-pagination>
      </el-col>
    </el-row>

    <!-- 弹框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      :before-close="this.beforeDialogClose"
    >
      <el-form
        :model="dialogForm"
        ref="dialogForm"
        :rules="dialogFormRules"
        label-width="150px"
        style="padding-right: 70px; margin-left: -30px;"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="用户名" prop="username">
              <el-input
                v-model="dialogForm.userName"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="登录名" prop="name">
              <el-input
                v-model="dialogForm.loginName"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="密码" prop="password">
              <el-input
                v-model="dialogForm.password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 备注 -->
        <el-row :gutter="20">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="dialogForm.remark" autocomplete="off"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="this.submitDialogForm" size="mini" type="primary"
          >确 定</el-button
        >
        <el-button @click="this.handleCloseDialog" size="mini">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 分配角色 -->
    <el-dialog :title="roleDialogTitle" :visible.sync="roleDialogVisible">
      <el-row>
        <el-col>
          <el-button
            type="primary"
            size="small"
            style="float: right"
            @click="saveUserRoles"
          >
            保存角色
          </el-button>
        </el-col>
        <el-col style="padding-left: 30px; margin-top: -30px;">
          <el-transfer
            v-model="checkedRoleIds"
            :props="roleDataProp"
            :data="roleDatas"
            :titles="['备选角色', '已选角色']"
          >
          </el-transfer>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- 加入组织 -->
    <el-dialog :title="orgDialogTitle" :visible.sync="orgDialogVisible">
      <el-row style="height: 230px; padding-left: 2rem">
        <el-col :span="14">
          <el-row :gutter="10" style="margin-bottom: 15px">
            <el-col :span="6" style="margin: 10px -20px 0px 10px">
              组织：</el-col
            >
            <el-col :span="12">
              <el-tree-select
                :elTreeProps="elTreeProps"
                :elTreeData="orgTreeDatas"
                :defaultSelectedId="defaultOrgId"
                :disabled="elTreeDisabled"
                @handleTreeSelected="handleTreeSelected($event)"
                @validateSelectTree="validateSelectTree"
              />
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="6" style="margin: 10px -20px 0px 10px">
              数据权限：</el-col
            >
            <el-col :span="12">
              <el-select v-model="defaultOrgPrem">
                <el-option
                  v-for="item in this.premTypeLabel"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="10">
          <el-row :gutter="20" style="margin-bottom: 20px">
            <el-button @click="addOrg" size="mini" type="primary"
              >加入组织</el-button
            >
          </el-row>
          <el-row :gutter="20">
            <el-tag
              v-for="(val, key) in checkedOrgIds"
              closable
              @close="delOrg(key)"
              style="margin: 0px 10px 10px 0px"
              :key="key"
            >
              {{ getOrgTreeName(orgTreeDatas, key) + ":" + getPremType(val) }}
            </el-tag>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import MixinCUD from "@/components/MixinCUD";
import Pagination from "@/components/Pagination";
import { addDict, arrDifferenceDet } from "../../utils/utils";
import {
  addUser,
  deleteUser,
  getUserList,
  updateUser,
} from "../../api/ca/ca_user";
import { Constants } from "../../lib/constants";
import axios from "axios";
import { getRoleList } from "../../api/ca/ca_role";
import { getRoleIdsbyUserid, saveUserRole } from "../../api/ca/ca_user_role";
import { Message } from "element-ui";
import { authFlag } from "../../store/modules/maintabs";
import { getOrgTree } from "../../api/ca/ca_org";
import ElTreeSelect from "@/components/TreeSelect";
import { getOrgIdsbyUserid, saveUserOrg } from "../../api/ca/ca_user_org";

export default {
  name: "CaUserList",
  mixins: [MixinCUD, Pagination],
  components: { ElTreeSelect },
  data() {
    return {
      // 列表数据
      tableData: [],
      queryFormRefName: "queryForm",
      // 查询表格
      queryform: {
        name: "",
        status: null,
      },
      // 详情页面路由名称
      infoRouteName: "CaUserInfo",

      // 增删改弹框
      dialogFormVisible: false,
      dialogTitle: "",
      dialogRefName: "dialogForm",
      dialogForm: {
        id: null,
        loginName: "",
        userName: "",
        password: "",
        remark: "",
      },
      dialogFormRules: {
        loginName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },

      handlingUserId: null, //正在为哪个用户分配角色userId
      handlingUsername: null, //正在为哪个用户分配角色username

      // 用户角色相关
      roleDialogTitle: "",
      roleDialogVisible: false,
      checkedRoleIds: [],
      lastCheckedRoleIds: [], // 上次网络保存
      roleDataProp: {
        key: "id",
        label: "roleName",
      },
      roleDatas: [],

      // 用户组织相关
      orgDialogVisible: false,
      orgDialogTitle: "加入组织",
      // 组织树
      orgTreeDatas: [],
      orgTree: [], //带root节点,用于新增修改
      elTreeProps: {
        // el-tree-select配置项（必选）
        value: "id",
        label: "orgName",
        children: "children",
      },
      defaultOrgId: "", // 组织id
      defaultOrgPrem: "0", // 数据权限
      elTreeDisabled: false,

      checkedOrgIds: {},
      lastCheckedOrgIds: {},

      premTypeLabel: [
        {
          value: "0",
          label: "本人",
        },
        {
          value: "1",
          label: "本组织及同级组织",
        },
        {
          value: "2",
          label: "本人及下属",
        },
        {
          value: "3",
          label: "本组织及下属组织",
        },
      ],
    };
  },
  methods: {
    /**
     * 获取数据
     */
    getData() {
      getUserList(addDict(this.queryForm, this.pagination)).then((res) => {
        this.setData(res);
      });
    },

    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.tableData = res.data;
        this.pagination.pageSize = res.pageSize;
        this.pagination.total = res.total;
      }
    },

    /**
     * 新增数据
     */
    addData() {
      addUser(this.dialogForm).then((res) => {
        this.afterDateRes(res);
      });
    },

    /**
     * 修改数据
     */
    updateData() {
      updateUser(this.dialogForm).then((res) => {
        this.afterDateRes(res);
      });
    },

    /**
     * 删除数据
     * @param row
     */
    deleteData(row) {
      deleteUser({ ids: row.id })
        .then((res) => {
          this.afterDateRes(res);
        })
        .catch((err) => {
          console.error(err.message);
        });
    },
    authFlag(flag) {
      return authFlag(this.$route.name + ":" + flag);
    },

    /**
     * 分配角色
     * @param index
     * @param row
     */
    assignRole(index, row) {
      this.roleDialogVisible = true;
      this.roleDialogTitle = "用户" + row.userName + "角色分配";
      this.handlingUserId = row.id;
      this.handlingUsername = row.userName;

      let _this = this;
      axios
        .all([getRoleList(), getRoleIdsbyUserid({ accountId: row.id })])
        .then(
          axios.spread(function (res1, res2) {
            // 两个请求都执行完成后，进入该函数
            if (res1.code === "0") {
              // 菜单树
              _this.roleDatas = res1.data;
            }
            if (res2.code === "0") {
              // 选中角色
              _this.checkedRoleIds = res2.data;
              _this.lastCheckedRoleIds = res2.data;
            }
          })
        );
    },

    /**
     * 保存用户角色关系
     */
    saveUserRoles() {
      let delRoleIds = arrDifferenceDet(
        this.lastCheckedRoleIds,
        this.checkedRoleIds
      );
      let addRoleIds = arrDifferenceDet(
        this.checkedRoleIds,
        this.lastCheckedRoleIds
      );
      saveUserRole({
        accountId: this.handlingUserId,
        addRoleIds: addRoleIds,
        delRoleIds: delRoleIds,
      }).then((res) => {
        if (res.code === "0") {
          Message.success(res.message);
          this.lastCheckedRoleIds = this.checkedRoleIds;
        } else {
          Message.error(res.message);
          console.error(res);
        }
      });
    },

    /**
     * 打开 加入组织
     * @param index
     * @param row
     */
    assignOrg(index, row) {
      this.orgDialogVisible = true;
      this.orgDialogTitle = "用户" + row.userName + "加入组织";
      this.handlingUserId = row.id;
      this.handlingUsername = row.userName;

      let _this = this;
      axios.all([getOrgTree(), getOrgIdsbyUserid({ userId: row.id })]).then(
        axios.spread(function (res1, res2) {
          // 两个请求都执行完成后，进入该函数
          if (res1.code === "0") {
            // 菜单树
            _this.orgTreeDatas = res1.data;
          }

          if (res2.code === "0") {
            // 选中组织
            _this.checkedOrgIds = res2.data;
          }
        })
      );
    },

    /**
     * 加入组织
     */
    addOrg() {
      saveUserOrg({
        userId: this.handlingUserId,
        addList: [{ orgId: this.defaultOrgId, premVal: this.defaultOrgPrem }],
        delList: null,
      }).then((res) => {
        if (res.code === "0") {
          Message.success(res.message);
          let _this = this;
          if (this.defaultOrgId) {
            _this.checkedOrgIds[this.defaultOrgId] = this.defaultOrgPrem;
          }
          this.checkedOrgIds = { ..._this.checkedOrgIds };
        } else {
          this.$message.error("请先选择组织，再进行添加操作");
        }
      });
    },

    /**
     * 删除数据权限
     * @param
     */
    delOrg(key) {
      saveUserOrg({
        userId: this.handlingUserId,
        addList: null,
        delList: [{ orgId: key }],
      }).then((res) => {
        if (res.code === "0") {
          let _this = this;
          if (key) {
            delete _this.checkedOrgIds[key];
          }
          this.checkedOrgIds = { ..._this.checkedOrgIds };
        }
      });
    },

    handleTreeSelected(value) {
      this.defaultOrgId = value;
    },
    validateSelectTree() {},

    /**
     * 转换节点类型
     */
    getPremType(value) {
      let label;
      let v = value.toString();
      this.premTypeLabel.forEach((item) => {
        if (item.value === v) {
          label = item.label;
        }
      });
      return label;
    },

    /**
     *  获取组织信息
     */
    getOrgTreeName(treeList, id) {
      let result;
      for (let i in treeList) {
        if (treeList[i].id === id) {
          result = treeList[i]["orgName"];
          break;
        }
        if (treeList[i].children && treeList[i].children.length) {
          result = this.getOrgTreeName(treeList[i].children, id);
          if (result) {
            break;
          }
        }
      }
      return result;
    },

    // 节点单击事件
    handleNodeClick(data) {
      getUserList({ orgId: data.id }).then((res) => {
        this.tableData = res.data;
      });
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
  },

  beforeRouteEnter(to, from, next) {
    getUserList({
      pageNum: Constants.pageNum,
      pageSize: Constants.pageSize,
    }).then((res) => {
      next((vm) => vm.setData(res));
    });
  },
  created() {
    getOrgTree().then((res) => {
      if (res.code === "0") {
        this.orgTree = res.data;
      }
    });
  },
};
</script>

<style>
.el-tree-node__label {
  font-size: 1rem;
  line-height: 6rem;
  letter-spacing: 1px;
}
.el-tree-node__content:hover {
  background: #d9eafa;
}
.el-tree {
  background-color: rgb(251, 251, 253);
}
</style>
