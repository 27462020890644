<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import tdTheme from "./theme.json";
import { on, off } from "@/lib/tools";
echarts.registerTheme("tdTheme", tdTheme);
export default {
  name: "ChartBar",
  props: {
    value: Array,
    text: String,
    subtext: String,
  },
  data() {
    return {
      dom: null,
      option: {},
    };
  },
  watch: {
    value(newVal, oldVal) {
      let xAxisData = newVal.map((_) => _.key);
      let seriesData = newVal.map((_) => _.count);
      let option = {
        title: {
          text: this.text,
          subtext: this.subtext,
          x: "center",
          y: 15,
        },
        xAxis: {
          type: "category",
          data: xAxisData,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: seriesData,
            type: "bar",
            center: ["50%", "80%"],
          },
        ],
      };
      this.option = option;
      this.dom.setOption(this.option);
      on(window, "resize", this.resize);
    },
    deep: true,
  },
  methods: {
    resize() {
      this.dom.resize();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.dom = echarts.init(this.$refs.dom, "tdTheme");
      this.dom.setOption(this.option);
      on(window, "resize", this.resize);
    });
  },
  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>