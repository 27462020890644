<template>
  <div>
    <!-- 查询 -->
    <el-card body-style="padding: 0" style="height: 5rem">
      <el-form ref="queryform" :model="queryform" label-width="80px">
        <el-row :gutter="30" style="padding: 20px 12px 20px 12px">
          <el-col :span="6">
            <el-form-item label="接口名称" prop="name">
              <el-input
                v-model="queryform.name"
                placeholder="请输入接口名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="13">
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="submitQueryForm()"
                icon="el-icon-search"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="small"
                plain
                @click="resetQueryForm('queryform')"
                icon="el-icon-refresh"
              >
                重置
              </el-button>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-plus"
                style="margin: 0 0 10px 20px"
                @click="handleAdd()"
                >新增
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <!-- 列表 -->
    <el-card>
      <el-table
        :data="tableData"
        row-key="id"
        border
        :header-cell-style="{ background: '#EBEEF5' }"
        style="width: 100%; margin-bottom: 20px"
      >
        <el-table-column prop="code" label="社区编码" width="" align="center" />
        <el-table-column
          prop="name"
          label="社区名称"
          width="100"
          align="center"
        />
        <el-table-column prop="netIp" label="内网IP" width="" align="center" />
        <el-table-column
          prop="localIp"
          label="宿主机IP"
          width=""
          align="center"
        />
        <el-table-column
          prop="port"
          label="业务映射端口"
          width="110"
          align="center"
        />
        <el-table-column prop="address" label="地址" width="" align="center" />
        <el-table-column
          prop="clientName"
          label="服务端名称"
          width="110"
          align="center"
        />
        <el-table-column
          prop="creatorId"
          label="创建者"
          width=""
          align="center"
        />
        <el-table-column
          prop="cstamp"
          label="创建时间戳"
          width="100"
          align="center"
        />
        <el-table-column
          prop="ustamp"
          label="修改时间戳"
          width="100"
          align="center"
        />
        <el-table-column
          prop="creatorName"
          label="创建者名字"
          width="100"
          align="center"
        />
        <el-table-column prop="remark" label="备注" width="70" align="center" />

        <!-- 均有 -->
        <!-- <el-table-column prop="creatorName" label="创建者" width="" align="center"/>
        <el-table-column prop="ustamp" label="最后更新时间" width="" align="center"/>
        <el-table-column prop="remark" label="备注" width="" align="center"/> -->
        <!-- 操作 -->
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="info"
              icon="el-icon-view"
              circle
              @click="handleInfo(scope.$index, scope.row, infoRouteName)"
            />
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              circle
              @click="handleEdit(scope.$index, scope.row, dialogTitle)"
            />
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="handleDelete(scope.$index, scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="this.pagination.pageNum"
        :page-size="this.pagination.pageSize"
        :total="this.pagination.total"
        @current-change="handlePageChange"
        style="float: right; margin-bottom: 10px"
      ></el-pagination>
    </el-card>

    <!-- 弹框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      :before-close="this.beforeDialogClose"
    >
      <el-form
        :model="dialogForm"
        ref="dialogForm"
        :rules="dialogFormRules"
        label-width="150px"
        style="padding-right: 70px;"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="社区编码" prop="code">
              <el-input v-model="dialogForm.code" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="社区名称" prop="name">
              <el-input v-model="dialogForm.name" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="地址" prop="address">
              <el-input
                v-model="dialogForm.address"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服务地址" prop="clientName">
              <el-input
                v-model="dialogForm.clientName"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="内网IP" prop="netIp">
              <el-input
                v-model="dialogForm.netIp"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="宿主机IP" prop="localIp">
              <el-input
                v-model="dialogForm.localIp"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="业务映射端口号" prop="port">
              <el-input v-model="dialogForm.port" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="dialogForm.remark"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="this.submitDialogForm" size="mini" type="primary"
          >确 定</el-button
        >
        <el-button @click="this.handleCloseDialog" size="mini">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MixinCUD from "@/components/MixinCUD";
import Pagination from "@/components/Pagination";
import { addDict } from "@/utils/utils";
import { addCM, deleteCM, getCMList, updateCM } from "@/api/fhs/fhs_cm";
import { Constants } from "@/lib/constants";

export default {
  name: "FhsCMList",
  mixins: [MixinCUD, Pagination],
  components: {},
  data() {
    return {
      // 列表数据
      tableData: [],
      queryFormRefName: "queryForm",
      // 查询表格
      queryform: {
        name: "",
        status: null,
      },
      // 详情页面路由名称
      infoRouteName: "FhsCMInfo",
      // 增删改弹框
      dialogFormVisible: false,
      dialogTitle: "",
      dialogRefName: "dialogForm",
      dialogForm: {
        code: "",
        address: "",
        clientName: "",
        deleted: "",
        id: "",
        netIp: "",
        localIp: "",
        name: "",
        port: "",
        remark: "",
      },
      dialogFormRules: {},
    };
  },
  methods: {
    /**
     * 获取数据
     */
    getData() {
      getCMList(addDict(this.queryForm, this.pagination)).then((res) => {
        this.setData(res);
      });
    },

    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.tableData = res.data;
        this.pagination.pageSize = res.pageSize;
        this.pagination.total = res.total;
      }
    },

    /**
     * 新增数据
     */
    addData() {
      addCM(this.dialogForm).then((res) => {
        this.afterDateRes(res);
      });
    },

    /**
     * 修改数据
     */
    updateData() {
      updateCM(this.dialogForm).then((res) => {
        this.afterDateRes(res);
      });
    },

    /**
     * 删除数据
     * @param row
     */
    deleteData(row) {
      deleteCM({ id: row.id })
        .then((res) => {
          this.afterDateRes(res);
        })
        .catch((err) => {
          console.error(err.message);
        });
    },
  },

  beforeRouteEnter(to, from, next) {
    getCMList({
      pageNum: Constants.pageNum,
      pageSize: Constants.pageSize,
    }).then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>