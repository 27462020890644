<template>
  <el-form ref="formRef" :model="form" class="body">
    <el-descriptions
      :column="2"
      :size="size"
      border
      style="font-size: 1.1rem; padding: 20px 30px"
      :labelStyle="{ 'text-align': 'center', 'width': '200px'  }"
      :contentStyle="{ 'text-align': 'center', 'width': '350px'  }"
    >
      <el-descriptions-item label="登录名">
        {{ form.loginName }}
      </el-descriptions-item>
      <el-descriptions-item label="登录时间">{{
        form.loginTime
      }}</el-descriptions-item>
      <el-descriptions-item label="创建者">{{
        form.creatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{
        form.cstamp
      }}</el-descriptions-item>
      <el-descriptions-item label="修改者">{{
        form.updatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="修改时间">{{
        form.ustamp
      }}</el-descriptions-item>
      <el-descriptions-item label="备注">{{
        form.remark
      }}</el-descriptions-item>
    </el-descriptions>
  </el-form>
</template>

<script>
import { getUser } from "../../api/ca/ca_user";

export default {
  name: "CaUserInfo",
  data() {
    return {
      formRef: null,
      form: {
        id: "",
        creatorId: "",
        creatorName: "",
        updatorId: "",
        updatorName: "",
        deleted: "",
        remark: "",
        cstamp: "",
        ustamp: "",
        loginName: "",
        loginTime: "",
        userName: "",
      },
    };
  },
  methods: {
    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.form = res.data;
        console.log(this.form);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    getUser({ id: to.query.id }).then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>
