<template>
  <div>
    <!-- 查询 -->
    <el-card body-style="padding: 0" style="height: 5rem">
      <el-form ref="queryform" :model="queryform" label-width="80px">
        <el-row :gutter="30" style="padding: 20px 12px 20px 12px">
          <el-col :span="6">
            <el-form-item label="接口名称" prop="name">
              <el-input
                v-model="queryform.name"
                placeholder="请输入接口名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="13">
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="submitQueryForm()"
                icon="el-icon-search"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="small"
                plain
                @click="resetQueryForm('queryform')"
                icon="el-icon-refresh"
              >
                重置
              </el-button>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-plus"
                style="margin: 0 0 10px 20px"
                @click="handleAdd()"
                >新增
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <!-- 列表 -->
    <el-card>
      <el-table
        :data="tableData"
        row-key="id"
        border
        :header-cell-style="{ background: '#EBEEF5' }"
        style="width: 100%; margin-bottom: 20px"
      >
        <el-table-column
          prop="appCode"
          label="小程序code"
          width="100"
          align="center"
        />
        <el-table-column
          prop="appId"
          label="小程序appId"
          width="110"
          align="center"
        />
        <el-table-column
          prop="encrypted"
          label="目标密文"
          width="210"
          align="center"
        />
        <el-table-column prop="id" label="id" width="" align="center" />
        <el-table-column
          prop="iv"
          label="加密算法的初始向量"
          width="150"
          align="center"
        />
        <el-table-column
          prop="loginTime"
          label="登录时间"
          width=""
          align="center"
        />
        <el-table-column
          prop="openId"
          label="微信程序返回ID"
          width="120"
          align="center"
        />
        <el-table-column prop="phone" label="手机号" width="" align="center" />
        <el-table-column
          prop="phoneJson"
          label="手机号"
          width="160"
          align="center"
        />
        <el-table-column
          prop="secret"
          label="小程序密钥"
          width="100"
          align="center"
        />
        <el-table-column
          prop="sessionKey"
          label="微信会话秘钥"
          width="110"
          align="center"
        />
        <el-table-column
          prop="tokenId"
          label="token表ID"
          width="90"
          align="center"
        />
        <el-table-column
          prop="type"
          label="0：ca；1：微信"
          width=""
          align="center"
        />

        <el-table-column label="操作" width="150" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="info"
              icon="el-icon-view"
              circle
              @click="handleInfo(scope.$index, scope.row, infoRouteName)"
            />
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="this.pagination.pageNum"
        :page-size="this.pagination.pageSize"
        :total="this.pagination.total"
        @current-change="handlePageChange"
        style="float: right; margin-bottom: 10px"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import MixinCUD from "@/components/MixinCUD";
import Pagination from "@/components/Pagination";
import { addDict } from "@/utils/utils";
import { getWXUserList } from "@/api/fhs/fhs_wxuser";
import { Constants } from "@/lib/constants";
import { authFlag } from "../../store/modules/maintabs";

export default {
  name: "FhsWXUserList",
  mixins: [MixinCUD, Pagination],
  components: {},
  data() {
    return {
      // 列表数据
      tableData: [],
      queryFormRefName: "queryForm",
      // 查询表格
      queryform: {
        name: "",
        status: null,
      },
      // 详情页面路由名称
      infoRouteName: "FhsWXUserInfo",
    };
  },
  methods: {
    // FhsWXUserList /fhs/wxuserlist el-icon-fa fa-user fhs/FhsWXUserList.vue
    /**
     * 获取数据
     */
    getData() {
      getWXUserList(addDict(this.queryForm, this.pagination)).then((res) => {
        this.setData(res);
      });
    },
    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.tableData = res.data;
        this.pagination.pageSize = res.pageSize;
        this.pagination.total = res.total;
      }
    },

    /**
     * 权限认证
     */
    authFlag(flag) {
      return authFlag(this.$route.name + ":" + flag);
    },
  },

  beforeRouteEnter(to, from, next) {
    getWXUserList({
      pageNum: Constants.pageNum,
      pageSize: Constants.pageSize,
    }).then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>