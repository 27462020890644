import http from "@/utils/http";

//=====================角色菜单========================
/**
 * 依据角色id获得服务id雷彪
 * @param data
 * @returns {*}
 */
export const getServIdsbyRoleid = (data) => {
    return http({
        url: '/api/ca/v2/roleServ/servIds',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

/**
 * 保存角色服务关系
 * @param roleId
 * @param addServIds
 * @param delServIds
 * @returns {*}
 */
export const saveRoleServ = (data) => {
    return http({
        url: '/api/ca/v2/roleServ',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

