<template>
  <el-form ref="formRef" :model="form" class="body">
    <el-descriptions
      :column="2"
      border
      style="font-size: 1.1rem; padding: 20px 30px"
      :labelStyle="{ 'text-align': 'center', width: '350px' }"
      :contentStyle="{ 'text-align': 'center', width: '350px' }"
    >
      <el-descriptions-item label="登录名">
        {{ form.userName }}
      </el-descriptions-item>
      <el-descriptions-item label="登录时间">{{
        form.loginTime
      }}</el-descriptions-item>
      <el-descriptions-item label="创建者">{{
        form.creatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{
        form.cstamp
      }}</el-descriptions-item>
      <el-descriptions-item label="修改者">{{
        form.updatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="修改时间">{{
        form.ustamp
      }}</el-descriptions-item>
      <el-descriptions-item label="备注">{{
        form.remark
      }}</el-descriptions-item>
    </el-descriptions>
  </el-form>
</template>

<script>
import { getUser } from "../../api/fhs/fhs_user";

export default {
  name: "FhsUserInfo",
  data() {
    return {
      formRef: null,
      form: {
        userName: "",
      },
    };
  },
  created() {
    // if (this.$route.query.type === "insert" || this.$route.query.type === "update") {
    //   this.isNoEdit = false;
    // }
  },
  methods: {
    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.form = res.data;
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    getUser({
      id: to.query.id,
    }).then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>
