<template>
  <el-form ref="formRef" :model="form" class="body">
    <el-descriptions
      :column="2"
      border
      style="font-size: 1.1rem; padding: 20px 30px"
      :labelStyle="{ 'text-align': 'center', width: '350px' }"
      :contentStyle="{ 'text-align': 'center', width: '420px' }"
    >
      <el-descriptions-item label="id">{{ form.id }}</el-descriptions-item>
      <el-descriptions-item label="小程序code">
        {{ form.appCode }}
      </el-descriptions-item>
      <el-descriptions-item label="小程序appId">{{ form.appId }}</el-descriptions-item>
      <el-descriptions-item label="目标密文">{{
        form.encrypted
      }}</el-descriptions-item>
      <el-descriptions-item label="加密算法的初始向量">
        {{ form.iv }}
      </el-descriptions-item>
      <el-descriptions-item label="登录时间">{{
        form.loginTime
      }}</el-descriptions-item>
      <el-descriptions-item label="微信程序返回ID">
        {{ form.openId }}
      </el-descriptions-item>
      <el-descriptions-item label="手机号">{{
        form.phone
      }}</el-descriptions-item>
      <el-descriptions-item label="手机号">{{
        form.phoneJson
      }}</el-descriptions-item>
      <el-descriptions-item label="小程序密钥">{{
        form.secret
      }}</el-descriptions-item>

      <el-descriptions-item label="微信会话秘钥">
        {{ form.sessionKey }}
      </el-descriptions-item>
      <el-descriptions-item label="token表ID">{{
        form.tokenId
      }}</el-descriptions-item>
      <el-descriptions-item label="0：ca；1：微信">{{ form.type }}</el-descriptions-item>
    </el-descriptions>
  </el-form>
</template>

<script>
import {getWXUser} from "../../api/fhs/fhs_wxuser";

export default {
  name: "FhsWXUserInfo",
  data() {
    return {
      formRef: null,
      form: {
        appCode: "",
        appId: "",
        encrypted: "",
        id: "",

        iv: "",
        loginTime: "",
        openId: "",
        phone: "",
        phoneJson: "",

        secret: "",
        sessionKey: "",
        tokenId: "",
        type: "",
      }
    }
  },
  created() {
    // if (this.$route.query.type === "insert" || this.$route.query.type === "update") {
    //   this.isNoEdit = false;
    // }
  },
  methods: {
    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === '0') {
        this.form = res.data;
      }
    },
  },


  beforeRouteEnter(to, from, next) {
    getWXUser({
      id: to.query.id,
    }).then((res) => {
      next((vm) => vm.setData(res));
    });
  }
};
</script>

//系统用户-详情按钮 FhsUserInfo /fhs/userinfo fhs/FhsUserInfo.vue FhsUserList:get

