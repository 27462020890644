import http from "@/utils/http";
//=====================接口========================


/**
 * 获取卡片数据
 * @param data
 * @returns {*}
 */
 export const getCardData = (data) => {
    return http({
        url: '/api/ca/v2/index/count',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};
/**
 * 获取饼图数据
 * @param data
 * @returns {*}
 */
 export const getPieData = (data) => {
    return http({
        url: '/api/ca/v2/index/scale',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};
/**
 * 获取柱状图数据
 * @param data
 * @returns {*}
 */
 export const getBarData = (data) => {
    return http({
        url: '/api/ca/v2/index/active',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};
/**
 * 获取折线图数据
 * @param data
 * @returns {*}
 */
 export const getLineData = (data) => {
    return http({
        url: '/api/ca/v2/index/weekCount',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};