import http from "@/utils/http";

//=====================服务接口========================


/**
 * 获取列表
 * @param data
 * @returns {*}
 */
export const getUserList = (data) => {
    return http({
        url: '/api/ca/v2/accountLp/list',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

/**
 * 获取详情
 * @param data
 * @returns {*}
 */
export const getUser = (data) => {
    return http({
        url: '/api/ca/v2/accountLp',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

