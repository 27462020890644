<template>
  <div>
    <!-- 查询 -->
    <el-card body-style="padding: 0" style="height: 5rem">
      <el-form ref="queryform" :model="queryform" label-width="80px">
        <el-row :gutter="30" style="padding: 20px 12px 20px 12px">
          <el-col :span="6">
            <el-form-item label="接口名称" prop="name">
              <el-input
                v-model="queryform.name"
                placeholder="请输入接口名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="13">
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="submitQueryForm()"
                icon="el-icon-search"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="small"
                plain
                @click="resetQueryForm('queryform')"
                icon="el-icon-refresh"
              >
                重置
              </el-button>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-plus"
                style="margin: 0 0 10px 20px"
                @click="handleAdd()"
                >新增
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <!-- 列表 -->
    <el-card>
      <el-table
        :data="tableData"
        row-key="id"
        border
        :header-cell-style="{ background: '#EBEEF5' }"
        style="width: 100%; margin-bottom: 20px"
      >
        <el-table-column
          prop="userName"
          label="用户名"
          width="200"
          align="center"
        />
        <el-table-column
          prop="loginName"
          label="登录名"
          width="150"
          align="center"
        />
        <el-table-column
          prop="loginTime"
          label="登录时间"
          width="150"
          align="center"
        />

        <!-- 均有 -->
        <el-table-column
          prop="creatorName"
          label="创建者"
          width=""
          align="center"
        />
        <el-table-column
          prop="ustamp"
          label="最后更新时间"
          width=""
          align="center"
        />
        <el-table-column prop="remark" label="备注" width="" align="center" />

        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="info"
              icon="el-icon-view"
              circle
              @click="handleInfo(scope.$index, scope.row, infoRouteName)"
            />
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="this.pagination.pageNum"
        :page-size="this.pagination.pageSize"
        :total="this.pagination.total"
        @current-change="handlePageChange"
        style="float: right; margin-bottom: 10px"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import MixinCUD from "@/components/MixinCUD";
import Pagination from "@/components/Pagination";
import { addDict } from "@/utils/utils";
import { getUserList } from "@/api/fhs/fhs_user";
import { Constants } from "@/lib/constants";

export default {
  name: "FhsUserList",
  mixins: [MixinCUD, Pagination],
  components: {},
  data() {
    return {
      // 列表数据
      tableData: [],
      queryFormRefName: "queryForm",
      // 查询表格
      queryform: {
        name: "",
        status: null,
      },
      // 详情页面路由名称
      infoRouteName: "FhsUserInfo",
    };
  },
  methods: {
    /**
     * 获取数据
     */
    getData() {
      getUserList(addDict(this.queryForm, this.pagination)).then((res) => {
        this.setData(res);
      });
    },
    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.tableData = res.data;
        this.pagination.pageSize = res.pageSize;
        this.pagination.total = res.total;
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    getUserList({
      pageNum: Constants.pageNum,
      pageSize: Constants.pageSize,
    }).then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>