import http from "@/utils/http";

//=====================用户角色========================
/**
 * 依据用户id获得角色id列表
 * @param data
 * @returns {*}
 */
export const getRoleIdsbyUserid = (data) => {
    return http({
        url: '/api/ca/v2/userRole/roleids',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

/**
 * 保存角色菜单关系
 * @param userId
 * @param addRoleIds
 * @param delRoleIds
 * @returns {*}
 */
export const saveUserRole = (data) => {
    return http({
        url: '/api/ca/v2/userRole',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

