<template>
  <div ref="dom" class="charts chart-pie"></div>
</template>

<script>
import * as echarts from "echarts";
import tdTheme from "./theme.json";
import { on, off } from "@/lib/tools";
echarts.registerTheme("tdTheme", tdTheme);
export default {
  name: "ChartPie",
  props: {
    value: Array,
    text: String,
    subtext: String,
  },
  data() {
    return {
      dom: null,
      option: {},
    };
  },
  methods: {
    resize() {
      this.dom.resize();
    },
  },
  watch: {
    value(newVal, oldVal) {
      let legend = newVal.map((_) => _.name);
      let option = {
        title: {
          text: this.text,
          subtext: this.subtext,
          x: "center",
          y: 15,
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "horization",
          data: legend,
          top: 20,
          left: 10,
        },
        series: [
          {
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: newVal,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.dom = echarts.init(this.$refs.dom, "tdTheme");
      this.option = option;
      this.dom.setOption(this.option);
      on(window, "resize", this.resize);
    },
    deep: true,
  },
  mounted() {
    this.$nextTick(() => {
      this.dom = echarts.init(this.$refs.dom, "tdTheme");
      this.dom.setOption(this.option);
      on(window, "resize", this.resize);
    });
  },
  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
