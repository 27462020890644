import http from "@/utils/http";
import qs from	'qs'
//=====================接口========================


/**
 * 获取列表
 * @param data
 * @returns {*}
 */
export const getUserList = (data) => {
    return http({
        url: '/api/ca/v2/accountLp/list',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

/**
 * 获取详情
 * @param data
 * @returns {*}
 */
export const getUser = (data) => {
    return http({
        url: '/api/ca/v2/accountLp',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

/**
 * 更新的一条数据记录
 * @param data
 * @returns {*}
 */
export const updateUser = (data) => {
    return http({
        url: '/api/ca/v2/accountLp',
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

/**
 * 新增一条数据记录
 * @param data
 * @returns {*}
 */
export const addUser = (data) => {
    return http({
        url: '/api/ca/v2/accountLp',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

/**
 * 删除一条数据记录
 * @param data
 * @returns {*}
 */
export const deleteUser = (data) => {
    return http({
        url: '/api/ca/v2/accountLp',
        method: 'delete',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

 /** 修改密码
 * @param data
 * @returns {*}
 */
export const updatePassword = (data) => {
    return http({
        url: '/api/ca/v2/password',
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}
