import http from "@/utils/http";

//=====================角色菜单========================
/**
 * 依据角色id货物菜单id雷彪
 * @param data
 * @returns {*}
 */
export const getMenuIdsbyRoleid = (data) => {
    return http({
        url: '/api/ca/v2/roleMenu/menuIds',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

/**
 * 保存角色菜单关系
 * @param roleId
 * @param addMenuIds
 * @param delMenuIds
 * @returns {*}
 */
export const saveRoleMenu = (data) => {
    return http({
        url: '/api/ca/v2/roleMenu',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

