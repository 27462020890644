<template>
  <div>
    <!-- 查询 -->
    <el-card body-style="padding: 0" style="height: 5rem">
      <el-form ref="queryform" :model="queryform" label-width="80px">
        <el-row :gutter="30" style="padding: 0px 12px">
          <el-col :span="6">
            <el-form-item label="用户名" prop="name">
              <el-input
                v-model="queryform.name"
                placeholder="请输入用户名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="13">
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="submitQueryForm()"
                icon="el-icon-search"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="small"
                plain
                @click="resetQueryForm('queryform')"
                icon="el-icon-refresh"
              >
                重置
              </el-button>
              <el-button
                type="primary"
                size="small"
                style="margin: 0 0 10px 20px"
                icon="el-icon-plus"
                @click="handleAdd()"
                >新增
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <!-- 列表 -->
    <el-card>
      <el-table
        :data="tableData"
        row-key="id"
        border
        stripe
        style="width: 100%; margin-bottom: 20px"
      >
        <!-- 改审计 -->
        <el-table-column
          prop="username"
          label="用户名"
          width="100"
          fixed="left"
          align="center"
        />
        <el-table-column prop="agent" label="代理" width="150" align="center" />
        <el-table-column prop="authIp" label="Ip" width="120" align="center" />
        <el-table-column
          prop="authModel"
          label="模式"
          width="80"
          align="center"
        />
        <el-table-column
          prop="authUrl"
          label="地址"
          width="160"
          align="center"
        />
        <el-table-column
          prop="authAgent"
          label="浏览器信息"
          width="150"
          align="center"
        />
        <el-table-column
          prop="requestStartTime"
          label="请求开始时间"
          width="150"
          align="center"
        />

        <!-- 均有 -->
        <el-table-column
          prop="creatorName"
          label="创建者"
          width=""
          align="center"
        />
        <el-table-column
          prop="ustamp"
          label="最后更新时间"
          width=""
          align="center"
        />
        <el-table-column prop="remark" label="备注" width="" align="center" />
        <!-- 操作 -->
        <el-table-column label="操作" width="80" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="authFlag('get')"
              size="mini"
              type="info"
              icon="el-icon-view"
              circle
              @click="handleInfo(scope.$index, scope.row, infoRouteName)"
            />
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="this.pagination.pageNum"
        :page-size="this.pagination.pageSize"
        :total="this.pagination.total"
        @current-change="handlePageChange"
        style="float: right; margin-bottom: 10px"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import MixinCUD from "@/components/MixinCUD";
import Pagination from "@/components/Pagination";
import MultiTree from "../../components/MultiTree";
import { addDict } from "../../utils/utils";
import { getAuditList } from "../../api/ca/ca_audit";
import { Constants } from "../../lib/constants";
import CheckboxPanel from "../../components/CheckboxPanel";
import { authFlag } from "../../store/modules/maintabs";

export default {
  name: "CaAuditList",
  mixins: [MixinCUD, Pagination],
  components: { MultiTree, CheckboxPanel },
  data() {
    return {
      // 列表数据
      tableData: [],
      queryFormRefName: "queryForm",
      // 查询表格
      queryform: {
        name: "",
        status: null,
      },
      // 详情页面路由名称
      infoRouteName: "CaAuditInfo",

      // 增删改弹框
      dialogFormVisible: false,
      dialogTitle: "",
      dialogRefName: "dialogForm",
      dialogForm: {
        id: null,
        username: "",
        remark: "",
      },
      dialogFormRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    /**
     * 权限认证
     */
    authFlag(flag) {
      return authFlag(this.$route.name + ":" + flag);
    },

    /**
     * 获取数据
     */
    getData() {
      getAuditList(addDict(this.queryForm, this.pagination)).then((res) => {
        this.setData(res);
      });
    },

    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.tableData = res.data;
        this.pagination.pageSize = res.pageSize;
        this.pagination.total = res.total;
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    getAuditList({
      pageNum: Constants.pageNum,
      pageSize: Constants.pageSize,
    }).then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>

<style scoped>
.el-form {
  margin-top: 20px;
}
</style>
