<template>
  <div>
    <!-- 查询 -->
    <el-card body-style="padding: 0">
      <el-form ref="queryform" :model="queryform" label-width="80px">
        <el-row :gutter="20" type="flex" justify="space-between">
          <el-col :span="6">
            <el-form-item label="登录用户" prop="name">
              <el-input v-model="queryform.name" placeholder="请输入登录用户"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="6">
            <el-form-item>
              <el-button type="primary" size="small"
                         @click="submitQueryForm()" icon="el-icon-search">
                查询
              </el-button>
              <el-button type="primary" size="small" plain
                         @click="resetQueryForm('queryform')" icon="el-icon-refresh">
                重置
              </el-button>

            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <!-- 列表 -->
    <el-card>
      <el-table :data="tableData" row-key="id"
                border :header-cell-style="{background: '#EBEEF5'}" style="width: 100%;margin-bottom: 20px;">
        <el-table-column prop="username" label="登录用户" width="200" align="center"/>
        <el-table-column prop="loginTime" label="登录时间" width="150" align="center"/>
        <el-table-column prop="remoteIp" label="远程Ip" width="150" align="center"/>
        <el-table-column prop="agent" label="浏览器信息" width="150" align="center"/>

        <!-- 均有 -->
        <el-table-column prop="creatorName" label="创建者" width="" align="center"/>
        <el-table-column prop="ustamp" label="最后更新时间" width="" align="center"/>
        <el-table-column prop="remark" label="备注" width="" align="center"/>
        <!-- 操作 -->
        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="info" icon="el-icon-view" circle
                       @click="handleInfo(scope.$index, scope.row, infoRouteName)"/>

          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination background layout="total, prev, pager, next"
                     :current-page="this.pagination.pageNum"
                     :page-size="this.pagination.pageSize"
                     :total="this.pagination.total"
                     @current-change="handlePageChange"
                     style="float: right;margin-bottom: 10px"></el-pagination>
    </el-card>


  </div>
</template>

<script>
import MixinCUD from '@/components/MixinCUD';
import Pagination from '@/components/Pagination';
import MultiTree from "../../components/MultiTree";
import {addDict, arrDifferenceDet} from "../../utils/utils";
import {addLogin, deleteLogin, getLoginList, updateLogin} from "../../api/ca/ca_login_n";
import {Constants} from "../../lib/constants";
import axios from "axios";
import {getMenuTree} from "../../api/ca/ca_menu";
import {getMenuIdsbyLoginid, saveLoginMenu} from "../../api/ca/ca_login_menu";
import {Message} from "element-ui";
import {getServTree} from "../../api/ca/ca_serv";
import CheckboxPanel from "../../components/CheckboxPanel";
import {getServIdsbyLoginid, saveLoginServ} from "../../api/ca/ca_login_serv";

export default {
  name: "CaLoginList",
  mixins: [MixinCUD, Pagination],
  components: {MultiTree, CheckboxPanel},
  data() {
    return {
      // 列表数据
      tableData: [],
      queryFormRefName: "queryForm",
      // 查询表格
      queryform: {
        name: "",
        status: null
      },
      // 详情页面路由名称
      infoRouteName: "CaLoginInfo",

      // 增删改弹框
      dialogFormVisible: false,
      dialogTitle: "",
      dialogRefName: "dialogForm",
      dialogForm: {
        id: null,
        username: '',
        remark: ''
      },
      dialogFormRules: {
        username: [
          {required: true, message: '请输入用户名称', trigger: 'blur'},
        ]
      },

    }
  },
  methods: {

    /**
     * 获取数据
     */
    getData() {
      getLoginList(addDict(this.queryForm, this.pagination))
          .then(res => {
            this.setData(res)
          })
    },

    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === '0') {
        this.tableData = res.data;
        this.pagination.pageSize = res.pageSize;
        this.pagination.total = res.total;
      }
    },

  },

  beforeRouteEnter(to, from, next) {
    getLoginList({
      pageNum: Constants.pageNum,
      pageSize: Constants.pageSize,
    }).then(res => {
      next(vm => vm.setData(res))
    })
  }
}
</script>

<style scoped>
.el-form {
  margin-top: 20px;
}
</style>
