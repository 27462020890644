<template>
  <div ref="dom"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/lib/tools";
export default {
  name: "ChartLine",
  props: {
    value: Array,
  },
  data() {
    return {
      dom: null,
      option: {},
    };
  },
  methods: {
    resize() {
      this.dom.resize();
    },
  },
  watch: {
    value(newVal, oldVal) {
      let tmp = [];
      for (let ch of newVal) {
        // console.log(ch.data);
        tmp.push({
          name: ch.name,
          type: "line",
          // stack: "总量",
          areaStyle: {
            normal: {
              color: 'rgba(128, 128, 128, 0.01)',
            },
          },
          data: ch.data,
        });
      }
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          top: "3%",
          left: "1.2%",
          right: "1%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
          },
        ],
        yAxis: [
          {
            type: "value",
            scale:true
          },
        ],
        series: tmp,
      };
      this.option = option;
      this.dom = echarts.init(this.$refs.dom);
      this.dom.setOption(this.option);
      on(window, "resize", this.resize);
    },
    deep: true,
  },
  mounted() {
    this.$nextTick(() => {
      this.dom = echarts.init(this.$refs.dom);
      this.dom.setOption(this.option);
      on(window, "resize", this.resize);
    });
  },
  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>