<template>
  <div>
    <!-- 查询 -->
    <el-card body-style="padding: 0" style="height: 5rem">
      <el-form ref="menuQueryForm" :model="menuQueryForm" label-width="80px">
        <el-row :gutter="30" style="padding: 20px 12px">
          <el-col :span="6">
            <el-form-item label="菜单名称" prop="name">
              <el-input
                v-model="menuQueryForm.name"
                placeholder="请输入菜单名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="13">
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="submitQueryForm()"
                icon="el-icon-search"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="small"
                plain
                @click="resetQueryForm('menuQueryForm')"
                icon="el-icon-refresh"
              >
                重置
              </el-button>

              <el-button
                type="primary"
                size="small"
                style="margin: 0 0 10px 20px"
                icon="el-icon-plus"
                @click="handleAdd()"
                >新增
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <!-- 列表 -->
    <el-card>
      <el-table
        :data="tableData"
        row-key="id"
        border
        stripe
        style="width: 100%; margin-bottom: 20px"
      >
        <el-table-column
          prop="menuName"
          label="菜单名称"
          width="220"
          fixed="left"
        />
        <el-table-column
          prop="name"
          label="路由名称"
          width="110"
          align="center"
        />
        <el-table-column
          prop="path"
          label="前端路由"
          width="130"
          align="center"
        />
        <el-table-column
          prop="menuType"
          label="菜单类型"
          width="100"
          :formatter="getMenuType"
          align="center"
        />
        <el-table-column
          prop="icon"
          label="菜单图标"
          width="150"
          align="center"
        />
        <el-table-column
          prop="menuSort"
          label="排序"
          width="50"
          align="center"
        />
        <el-table-column
          prop="viewImport"
          label="前端路由组件Import路径"
          width="180"
          align="center"
        />
        <el-table-column
          prop="authFlag"
          label="前端鉴权标识"
          width="180"
          align="center"
        />

        <!-- 均有 -->
        <el-table-column
          prop="creatorName"
          label="创建者"
          width=""
          align="center"
        />
        <el-table-column
          prop="ustamp"
          label="最后更新时间"
          width=""
          align="center"
        />
        <el-table-column prop="remark" label="备注" width="" align="center" />

        <!-- 操作 -->
        <el-table-column label="操作" width="130" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="authFlag('get')"
              size="mini"
              type="info"
              icon="el-icon-view"
              circle
              @click="handleInfo(scope.$index, scope.row, infoRouteName)"
            />
            <el-button
              v-if="authFlag('put')"
              size="mini"
              type="primary"
              icon="el-icon-edit"
              circle
              @click="handleEdit(scope.$index, scope.row)"
            />
            <el-button
              v-if="authFlag('delete')"
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="handleDelete(scope.$index, scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 弹框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      :before-close="beforeDialogClose"
    >
      <el-form
        :model="dialogForm"
        ref="dialogForm"
        :rules="dialogFormRules"
        label-width="150px"
        style="padding-right: 80px"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="上级菜单" prop="menuPid">
              <el-tree-select
                :elTreeProps="elTreeProps"
                :elTreeData="menuTree"
                :defaultSelectedId="dialogForm.menuPid"
                :disabled="elTreeDisabled"
                @handleTreeSelected="handleTreeSelected($event)"
                @validateSelectTree="validateSelectTree"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="菜单类型" prop="menuType">
              <el-select v-model="dialogForm.menuType" clearable>
                <el-option
                  v-for="(item, index) in menuTypeLabel"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单名称" prop="menuName">
              <el-input
                v-model="dialogForm.menuName"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="排序序号" prop="menuSort">
              <el-input-number
                v-model="dialogForm.menuSort"
                :disabled="dialogForm.menuSortDisabled"
                :min="1"
                label="排序序号"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单图标" prop="icon">
              <el-input v-model="dialogForm.icon" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="路由名称" prop="name">
              <el-input
                v-model="dialogForm.name"
                autocomplete="off"
                :disabled="dialogForm.nameDisabled"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="前端路由" prop="path">
              <el-input v-model="dialogForm.path" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="前端组件import路径" prop="viewImport">
              <el-input
                v-model="dialogForm.viewImport"
                autocomplete="off"
                :disabled="dialogForm.viewImportDisabled"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="前端鉴权标识" prop="authFlag">
              <el-input
                v-model="dialogForm.authFlag"
                autocomplete="off"
                :disabled="dialogForm.authFlagDisabled"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 备注 -->
        <el-row :gutter="20">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="dialogForm.remark" autocomplete="off"></el-input>
          </el-form-item>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="submitDialogForm" size="mini" type="primary"
          >确 定
        </el-button>
        <el-button @click="handleCloseDialog" size="mini">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addMenu,
  deleteMenu,
  getMenuTree,
  updateMenu,
} from "../../api/ca/ca_menu";
import ElTreeSelect from "@/components/TreeSelect";
import DictSelect from "@/components/DictSelect";
import MixinCUD from "@/components/MixinCUD";
import { Message } from "element-ui";
import { authFlag } from "../../store/modules/maintabs";

export default {
  name: "CaMenuList",
  mixins: [MixinCUD],
  components: { ElTreeSelect, DictSelect },
  data() {
    return {
      infoRouteName: "CaMenuInfo",
      menuTypeLabel: [
        {
          value: "FATHER",
          label: "父节点",
          menuSortDisabled: false,
          authFlagDisabled: true,
          nameDisabled: true,
          viewImportDisabled: true,
        },
        {
          value: "CHILD",
          label: "子节点",
          menuSortDisabled: false,
          authFlagDisabled: true,
          nameDisabled: false,
          viewImportDisabled: false,
        },
        {
          value: "BUTTON",
          label: "按钮",
          menuSortDisabled: true,
          authFlagDisabled: false,
          nameDisabled: false,
          viewImportDisabled: false,
        },
        {
          value: "PAGE",
          label: "独立页面",
          menuSortDisabled: false,
          authFlagDisabled: true,
          nameDisabled: false,
          viewImportDisabled: false,
        },
      ],
      tableData: [], //不带root节点，用于table展示
      menuTree: [], //带root节点,用于新增修改
      menuQueryForm: {
        name: "",
        status: null,
      },
      elTreeDisabled: false,
      elTreeProps: {
        // el-tree-select配置项（必选）
        value: "id",
        label: "menuName",
        children: "children",
      },
      dialogFormVisible: false,
      hc: true,
      dialogTitle: "",
      dialogForm: {
        id: null,
        menuPid: null, // el-tree-select初始ID（可选）
        menuName: "",
        name: "",
        menuType: "",
        path: "",
        icon: "",
        menuSort: "",
        viewImport: "",
        authFlag: "",
        menuSortDisabled: false,
        authFlagDisabled: false,
        nameDisabled: false,
        viewImportDisabled: false,
      },
      dialogFormRules: {
        menuName: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
        ],
        menuType: [
          { required: true, message: "请选择菜单类型", trigger: "blur" },
        ],
      },
      fatherRules: {
        menuName: [
          {
            required: true,
            message: "请输入菜单名称",
            trigger: "change",
          },
        ],
        menuType: [
          { required: true, message: "请选择菜单类型", trigger: "blur" },
        ],
        path: [{ required: true, message: "请输入前端路由", trigger: "blur" }],
        icon: [{ required: true, message: "请输入菜单图标", trigger: "blur" }],
      },
      childPageRules: {
        menuName: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
        ],
        menuType: [
          { required: true, message: "请选择菜单类型", trigger: "blur" },
        ],
        path: [{ required: true, message: "请输入前端路由", trigger: "blur" }],
        icon: [{ required: true, message: "请输入菜单图标", trigger: "blur" }],
        viewImport: [
          {
            required: true,
            message: "请输入需要import的前端组件",
            trigger: "blur",
          },
        ],
        name: [{ required: true, message: "请输入路由名称", trigger: "blur" }],
      },
      buttonRules: {
        menuName: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
        ],
        menuType: [
          { required: true, message: "请选择菜单类型", trigger: "blur" },
        ],
        authFlag: [
          { required: true, message: "请输入鉴权标识", trigger: "blur" },
        ],
      },
      getButtonRules: {
        menuName: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
        ],
        menuType: [
          { required: true, message: "请选择菜单类型", trigger: "blur" },
        ],
        path: [{ required: true, message: "请输入前端路由", trigger: "blur" }],
        viewImport: [
          {
            required: true,
            message: "请输入需要import的前端组件",
            trigger: "blur",
          },
        ],
        authFlag: [
          { required: true, message: "请输入鉴权标识", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入路由名称", trigger: "blur" }],
      },
    };
  },
  watch: {
    "dialogForm.menuType": {
      handler: function () {
        if (!this.dialogForm.id) {
          this.$refs.dialogForm.clearValidate();
          this.resetDialogForm();
        }
        for (let item of this.menuTypeLabel) {
          if (item.value === this.dialogForm.menuType) {
            this.dialogForm.menuSortDisabled = item.menuSortDisabled;
            this.dialogForm.authFlagDisabled = item.authFlagDisabled;
            this.dialogForm.nameDisabled = item.nameDisabled;
            this.dialogForm.viewImportDisabled = item.viewImportDisabled;
          }
        }
        if (this.dialogForm.menuType === "FATHER") {
          this.dialogFormRules = this.fatherRules;
        } else if (
          this.dialogForm.menuType === "CHILD" ||
          this.dialogForm.menuType === "PAGE"
        ) {
          this.dialogFormRules = this.childPageRules;
        } else if (this.dialogForm.menuType === "BUTTON") {
          this.dialogFormRules = this.buttonRules;
        }
      },
    },
    "dialogForm.authFlag": {
      handler: function () {
        if (!this.dialogForm.id) {
          this.$refs.dialogForm.clearValidate();
        }
        if (this.dialogForm.authFlag) {
          if (
            this.dialogForm.authFlag.endsWith("get") ||
            this.dialogForm.authFlag.endsWith("GET") ||
            this.dialogForm.authFlag.endsWith("Get")
          ) {
            this.dialogFormRules = this.getButtonRules;
          } else {
            this.dialogFormRules = this.buttonRules;
          }
        }
      },
    },
    deep: true,
  },
  methods: {
    authFlag(flag) {
      return authFlag(this.$route.name + ":" + flag);
    },

    /**
     * 转换菜单类型
     * @param row
     * @param column
     * @returns {string}
     */
    getMenuType(row, column) {
      let label;
      this.menuTypeLabel.forEach((item) => {
        if (item.value === row.menuType) {
          label = item.label;
        }
      });
      return label;
    },

    getData() {
      let _this = this;
      getMenuTree().then((res) => {
        _this.setData(res);
      });
      // axios.all([
      //   getMenuTree(),
      //   getMenuList(addDict(this.menuQueryForm, this.pagination))])
      //     .then(axios.spread(function (res1, res2) {
      //       // 两个请求都执行完成后，进入该函数
      //       _this.setData(res1, res2);
      //     }));
    },
    handleAdd() {
      this.elTreeDisabled = false;
      this.dialogFormVisible = true;
      this.dialogTitle = "新增菜单项";
      this.resetDialogForm();
      this.dialogForm.menuType = "";
    },
    handleEdit(index, row) {
      this.elTreeDisabled = true;
      this.dialogFormVisible = true;
      this.dialogTitle = "修改菜单项";
      this.resetDialogForm();
      this.dialogForm = { ...row };
    },
    resetDialogForm() {
      this.dialogForm.id = null;
      this.dialogForm.menuPid = null;
      this.dialogForm.menuName = "";
      this.dialogForm.path = "";
      this.dialogForm.icon = "";
      this.dialogForm.menuSort = "";
      this.dialogForm.viewImport = "";
      this.dialogForm.authFlag = "";
      this.dialogForm.menuSortDisabled = false;
      this.dialogForm.authFlagDisabled = false;
      this.dialogForm.nameDisabled = false;
      this.dialogForm.viewImportDisabled = false;
      let rules = {
        menuName: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
        ],
        menuType: [
          { required: true, message: "请选择菜单类型", trigger: "blur" },
        ],
      };
      this.dialogFormRules = rules;
    },
    submitDialogForm() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.$confirm("确定提交数据么?")
            .then((_) => {
              if (this.elTreeDisabled) {
                updateMenu(this.dialogForm).then((res) => {
                  if (res.code === "0") {
                    Message.success(res.message);
                    this.submitQueryForm(); //修改之后，重新查询table
                    this.handleCloseDialog();
                  } else {
                    Message.error(res.message);
                    console.error(res);
                  }
                });
              } else {
                addMenu(this.dialogForm).then((res) => {
                  if (res.code === "0") {
                    Message.success(res.message);
                    this.submitQueryForm(); //修改之后，重新查询table
                    this.handleCloseDialog();
                  } else {
                    Message.error(res.message);
                    console.error(res);
                  }
                });
              }
              //取消新增或修改也要重置表单
            })
            .catch((_) => {
              this.handleCloseDialog();
            });
        } else {
          return false;
        }
      });
    },
    handleCloseDialog() {
      //resetFields就是一个坑，有两个作用
      //1.重置的值不是空的，而是第一次被赋予的值。
      //第一次dialogForm赋空值，后续才能重置为空值。
      //这就是我们在新增修改打开弹出框操作的时候，调用resetDialogFrom清空数据的原因。
      //2.清空校验结果
      this.$refs["dialogForm"].resetFields();
      this.dialogFormVisible = false;
    },
    beforeDialogClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.$refs["dialogForm"].resetFields();
          done();
        })
        .catch((_) => {});
    },
    handleDelete(index, row) {
      this.$confirm("确定删除[" + row.menuName + "]?").then((_) => {
        this.dialogForm = { ...row };
        deleteMenu({ id: row.id })
          .then((res) => {
            if (res.code === "0") {
              Message.success(res.message);
              this.submitQueryForm();
            } else {
              Message.error(res.message);
              console.error(res);
            }
          })
          .catch((err) => {
            console.error(err.message);
          });
      });
    },
    handleTreeSelected(value) {
      this.dialogForm.menuPid = value;
      this.$refs.dialogForm.validateField("menuPid");
    },
    validateSelectTree() {
      this.$refs.dialogForm.validateField("menuPid");
    },

    setData(menuTreeRes) {
      if (menuTreeRes.code === "0") {
        this.menuTree = menuTreeRes.data;
        this.tableData = menuTreeRes.data;
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    getMenuTree().then((res) => {
      next((vm) => vm.setData(res));
    });
    // axios.all([
    //   getMenuTree(),
    //   getMenuList({
    //     pageNum: Constants.pageNum,
    //     pageSize: Constants.pageSize,
    //   })])
    //     .then(axios.spread(function (res1, res2) {
    //       // 两个请求都执行完成后，进入该函数
    //       next(vm => {
    //         vm.setData(res1, res2);
    //       })
    //     }));
  },
};
</script>
