<template>
  <form onsubmit="return false">
    <textarea id="message" style="width: 400px;height: 200px"></textarea>

    <input type="button" value="发送数据" @click="send">

    <h3>服务器输出：</h3>

    <textarea id="responseText" style="width: 400px;height: 300px;"></textarea>

    <input type="button" onclick="javascript:document.getElementById('responseText').value=''" value="清空数据">
  </form>
</template>

<script>

export default {
  name: "CaUserInfo",
  data() {
    return {
      message: "",
    }
  },
  created() {
    const router = this.$route;
    // initWebSocket(this.getMsg, "AlcCushionData", "1d00200011504e5646343920");
    // initWebSocket(this.getMsg, "AlcCushionState", "");
  },
  destroyed() {
    // 在需要的时候卸载监听事件
    // destroyedWebSocket();
  },
  methods: {
    /**
     * 点击发送数据
     */
    send() {
      sendMsg(document.getElementById('message').value)
    },

    /**
     * 数据接收
     */
    getMsg(msg) {
      // console.log(msg);
      var ta = document.getElementById("responseText");
      ta.value = ta.value + "\n" +msg;
    },
    // /**
    //  * 初始化weosocket
    //  */
    // initWebSocket() {
    //   const router = this.$route;
    //   // const wsuri = "ws://127.0.0.1:12345/ws?userid="+router.query.id;
    //   // const wsuri = "ws://192.168.1.201:12345/ws?userid="+router.query.id;
    //   // socket = new WebSocket("ws://127.0.0.1:15010/ws?cushionId=2", ["eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpblRpbWUiOiIyMDIyLTAxLTE4IDE0OjU4OjUxIiwidXNlcklkIjoiMTIzNDU2Nzg5NzY0MzIyMTM0MTIzNDEyNDMifQ.LYhJJjsXbHdUZjRL1usDxUewG3NlMCPeDNuSBJAxpYo"]);
    //   const url = `${location.protocol === 'https:' ? 'wss' : 'ws'}://${location.host}/socket`
    //   const wsuri = url + "/ws?type=AlcCushionData&id=1d00200011504e5646343920"
    //   // const wsuri = url + "/ws?type=AlcCushionState&id=1d00200011504e5646343920"
    //   var that = this
    //   this.websock = new WebSocket(wsuri, getToken());
    //   this.websock.onmessage = that.websockonmessage;
    //   this.websock.onopen = that.websockonopen;
    //   this.websock.onerror = that.websockonerror;
    //   this.websock.onclose = that.websockclose;
    //   console.log(this.websock.readyState)
    // },
    //
    // /**
    //  * 连接开启
    //  */
    // websockonopen() {
    //   // 心跳重置并开启
    //   this.resetHeartCheck()
    //   this.startHeartCheck()
    //   this.resetReconn()
    //   this.lockReconnect = false;
    //
    //   var ta = document.getElementById("responseText")
    //   ta.value = "连接开启"
    // },
    //
    // /**
    //  * 连接建立失败重连
    //  */
    // websockonerror() {
    //   console.log("断开连接")
    //   this.resetHeartCheck()
    //   this.websockReconnect()
    // },

    // /**
    //  * 数据接收
    //  */
    // websockonmessage(e) {
    //   // 拿到任何消息都说明当前连接是正常的，重置心跳
    //   this.resetHeartCheck()
    //   this.startHeartCheck()
    //
    //   // var ta = document.getElementById("responseText");
    //   // ta.value = ta.value + "\n" + e.data;
    //   console.log( e.data);
    // },

    // /**
    //  * 重连
    //  */
    // websockReconnect() {
    //   if (this.lockReconnect) return
    //   var self = this;
    //   this.lockReconnect = true
    //   this.reTimeoutObj = setTimeout(function () {     //没连接上会一直重连，设置延迟避免请求过多
    //     self.initWebSocket()
    //   }, this.reConTime);
    // },

    // /**
    //  * 关闭
    //  * @param e
    //  */
    // websockclose(e) {
    //   console.log("websocketclose ", e)
    //   console.log('websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
    //   if (e.code === 1005) {
    //     this.wsclose()
    //   }
    // },
    //
    //
    // /**
    //  *  websocket数据发送
    //  */
    // wssend(msg) {
    //   if (!window.WebSocket) {
    //     return;
    //   }
    //
    //   //当websocket状态打开
    //   if (this.websock.readyState == WebSocket.OPEN) {
    //     this.websock.send(msg);
    //   } else {
    //     console.error("连接没有开启")
    //   }
    // },

    // /**
    //  * 关闭
    //  * @param e
    //  */
    // wsclose() {
    //   this.websock.close()
    // },
    //
    //
    // /**
    //  * 开始心跳检测
    //  */
    // startHeartCheck() {
    //   var self = this;
    //   this.timeoutObj = setInterval(function () {
    //     //这里发送一个心跳，后端收到后，返回一个心跳消息，
    //     //onmessage拿到返回的心跳就说明连接正常
    //     self.wssend("ping")
    //     console.log("ping!")
    //     //如果超过一定时间还没重置，说明后端主动断开了
    //     // self.serverTimeoutObj = setTimeout(function () {
    //     //   //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
    //     //   self.wsclose();
    //     // }, self.timeout)
    //   }, this.timeout)
    // },
    //
    // /**
    //  * 重置心跳
    //  */
    // resetHeartCheck() {
    //   clearInterval(this.timeoutObj)
    //   // clearTimeout(this.timeoutObj);
    //   // clearTimeout(this.serverTimeoutObj);
    // },
    //
    // /**
    //  * 重置重连
    //  */
    // resetReconn(){
    //   clearTimeout(this.reTimeoutObj)
    // }
  },


};
</script>

