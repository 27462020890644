<template>
  <el-form ref="formRef" :model="form" class="body">
    <el-descriptions
      :column="2"
      border
      style="font-size: 1.1rem; padding: 20px 30px"
      :labelStyle="{ 'text-align': 'center', 'width': '350px'  }"
      :contentStyle="{ 'text-align': 'center', 'width': '350px' }"
    >
      <el-descriptions-item label="菜单名称">
        {{ form.menuName }}
      </el-descriptions-item>
      <el-descriptions-item label="菜单类型">{{
        form.menuType
      }}</el-descriptions-item>
      <el-descriptions-item label="父菜单Id">
        {{ form.menuPid }}
      </el-descriptions-item>
      <el-descriptions-item label="菜单排序序号">{{
        form.menuSort
      }}</el-descriptions-item>
      <el-descriptions-item label="菜单图标">
        {{ form.icon }}
      </el-descriptions-item>
      <el-descriptions-item label="路径跳转名">{{
        form.name
      }}</el-descriptions-item>
      <el-descriptions-item label="跳转URL">
        {{ form.path }}
      </el-descriptions-item>
      <el-descriptions-item label="前端鉴权标识">{{
        form.authFlag
      }}</el-descriptions-item>
      <el-descriptions-item label="前端组件import路径">{{
        form.viewImport
      }}</el-descriptions-item>
      <el-descriptions-item label="创建者">{{
        form.creatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{
        form.cstamp
      }}</el-descriptions-item>
      <el-descriptions-item label="修改者">{{
        form.updatorName
      }}</el-descriptions-item>
      <el-descriptions-item label="修改时间">{{
        form.ustamp
      }}</el-descriptions-item>
      <el-descriptions-item label="备注">{{
        form.remark
      }}</el-descriptions-item>
    </el-descriptions>
  </el-form>
</template>

<script>
import { getMenu } from "../../api/ca/ca_menu";

export default {
  name: "CaMenuInfo",
  data() {
    return {
      formRef: null,
      form: {
        authFlag: "",
        creatorId: "",
        creatorName: "",
        cstamp: "",
        deleted: "",
        icon: "",
        id: "",
        menuCode: "",
        menuName: "",
        menuPid: null, // el-tree-select初始ID（可选）
        menuSort: "",
        menuType: "",
        name: "",
        path: "",
        updatorId: "",
        updatorName: "",
        ustamp: "",
        viewImport: "",
      },
    };
  },
  methods: {
    /**
     * 设置数据
     * @param res
     */
    setData(res) {
      if (res.code === "0") {
        this.form = res.data;
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    getMenu({
      id: to.query.id,
    }).then((res) => {
      next((vm) => vm.setData(res));
    });
  },
};
</script>
