<template>
  <div style="margin: 15px">
    <el-row :gutter="20">
      <el-col
        :xs="12"
        :md="8"
        :lg="4"
        style="margin: 20px 0"
        v-for="infor in inforCardData"
        :key="infor.title"
      >
        <infor-card
          :color="infor.color"
          :icon="infor.icon"
          :icon-size="36"
          :title="infor.title"
          style="
            height: 120px;
            box-shadow: 2px 2px 2px 1px #888888;
            border-radius: 5px;
          "
        >
          <count-to-page :end="infor.count" count-class="count-style" />
          <p>{{ infor.title }}</p>
        </infor-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 20px">
      <el-col :md="24" :lg="8" style="margin-bottom: 20px">
        <chart-pie
          style="height: 300px; box-shadow: 2px 2px 2px 1px #888888"
          :value="pieData"
          text="用户访问来源"
        ></chart-pie>
      </el-col>
      <el-col :md="24" :lg="16" style="margin-bottom: 20px">
        <chart-bar
          style="height: 300px; box-shadow: 2px 2px 2px 1px #888888"
          :value="barData"
          text="每周用户活跃量"
        />
      </el-col>
    </el-row>
    <el-row>
      <chart-line
        :value="lineData"
        style="
          height: 310px;
          margin: 20px 0;
          padding: 10px;
          box-shadow: 2px 2px 1px 1px #888888;
        "
      />
    </el-row>
  </div>
</template>

<script>
import ChartPie from "./index/ChartPie.vue";
import ChartBar from "./index/ChartBar.vue";
import ChartLine from "./index/ChartLine.vue";
import InforCard from "./index/InforCard.vue";
import CountToPage from "./index/CountToPage.vue";
import {
  getCardData,
  getBarData,
  getPieData,
  getLineData,
} from "@/api/index/index_info";

export default {
  name: "FirstPage",
  components: {
    ChartPie,
    ChartBar,
    ChartLine,
    InforCard,
    CountToPage,
  },
  data() {
    return {
      inforCardData: [
        {
          title: "用户总数",
          icon: "md-person-add",
          count: 0,
          color: "#F87474",
        },
        {
          title: "昨日新用户数量",
          icon: "md-locate",
          count: 0,
          color: "#FFB562",
        },
        {
          title: "昨日登录数量",
          icon: "md-help-circle",
          count: 0,
          color: "#3AB0FF",
        },
        {
          title: "昨日请求总数",
          icon: "md-share",
          count: 0,
          color: "#3BACB6",
        },
        {
          title: "昨日认证数量",
          icon: "md-chatbubbles",
          count: 0,
          color: "#C499BA",
        },
        { title: "昨日错误数量", icon: "md-map", count: 0, color: "#6CC4A1" },
      ],
      pieData: [],
      barData: [
        { key: "周一", count: 0 },
        { key: "周二", count: 0 },
        { key: "周三", count: 0 },
        { key: "周四", count: 0 },
        { key: "周五", count: 0 },
        { key: "周六", count: 0 },
        { key: "周日", count: 0 },
      ],
      lineData: [],
    };
  },
  methods: {
    getCard() {
      let self = this;
      getCardData().then((res) => {
        for (let ch in res.data) {
          if (ch === "accountCountAll") {
            self.inforCardData[0].count = res.data[ch];
          }
          if (ch === "accountCountNew") {
            self.inforCardData[1].count = res.data[ch];
          }
          if (ch === "loginCount") {
            self.inforCardData[2].count = res.data[ch];
          }
          if (ch === "requestCount") {
            self.inforCardData[3].count = res.data[ch];
          }
          if (ch === "authCount") {
            self.inforCardData[4].count = res.data[ch];
          }
          if (ch === "errCount") {
            self.inforCardData[5].count = res.data[ch];
          }
        }
      });
    },
    getBar() {
      let self = this;
      getBarData().then((res) => {
        for (let ch of res.data) {
          if (ch.key === "Monday") {
            let tmp = {
              key: "周一",
              count: ch.count,
            };
            self.$set(self.barData, 0, tmp);
          }
          if (ch.key === "Tuesday") {
            let tmp = {
              key: "周二",
              count: ch.count,
            };
            self.$set(self.barData, 1, tmp);
          }
          if (ch.key === "Wednesday") {
            let tmp = {
              key: "周三",
              count: ch.count,
            };
            self.$set(self.barData, 2, tmp);
          }
          if (ch.key === "Thursday") {
            let tmp = {
              key: "周四",
              count: ch.count,
            };
            self.$set(self.barData, 3, tmp);
          }
          if (ch.key === "Friday") {
            let tmp = {
              key: "周五",
              count: ch.count,
            };
            self.$set(self.barData, 4, tmp);
          }
          if (ch.key === "Saturday") {
            let tmp = {
              key: "周六",
              count: ch.count,
            };
            self.$set(self.barData, 5, tmp);
          }
          if (ch.key === "Sunday") {
            let tmp = {
              key: "周日",
              count: ch.count,
            };
            self.$set(self.barData, 6, tmp);
          }
        }
      });
    },
    getPie() {
      let self = this,
        i = 0;
      getPieData().then((res) => {
        for (let ch of res.data) {
          let tmp = {};
          tmp.name = ch.key;
          tmp.value = ch.count;
          self.$set(self.pieData, i++, tmp);
        }
      });
    },
    getLine() {
      let self = this;
      getLineData().then((res) => {
        for (let ch in res.data) {
          if (ch === "accountCountNew") {
            let tmp = {
              name: "昨日新用户数量",
              data: res.data[ch],
            };
            self.$set(self.lineData, 0, tmp);
          }
          if (ch === "loginCount") {
            let tmp = {
              name: "昨日登录数量",
              data: res.data[ch],
            };
            self.$set(self.lineData, 1, tmp);
          }
          if (ch === "requestCount") {
            let tmp = {
              name: "昨日请求总数",
              data: res.data[ch],
            };
            self.$set(self.lineData, 2, tmp);
          }
          if (ch === "authCount") {
            let tmp = {
              name: "昨日认证数量",
              data: res.data[ch],
            };
            self.$set(self.lineData, 3, tmp);
          }
          if (ch === "errCount") {
            let tmp = {
              name: "昨日错误数量",
              data: res.data[ch],
            };
            self.$set(self.lineData, 4, tmp);
          }
        }
      });
    },
  },
  created() {
    this.$nextTick(() => {
      this.getBar();
      this.getPie();
      this.getCard();
      this.getLine();
    });
  },
};
</script>
