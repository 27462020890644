import http from "@/utils/http";

//=====================接口========================


/**
 * 获取列表
 * @param data
 * @returns {*}
 */
export const getRoleList = (data) => {
    return http({
        url: '/api/ca/v2/role/list',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

/**
 * 获取详情
 * @param data
 * @returns {*}
 */
export const getRole = (data) => {
    return http({
        url: '/api/ca/v2/role',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

/**
 * 更新的一条数据记录
 * @param data
 * @returns {*}
 */
export const updateRole = (data) => {
    return http({
        url: '/api/ca/v2/role',
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

/**
 * 新增一条数据记录
 * @param data
 * @returns {*}
 */
export const addRole = (data) => {
    return http({
        url: '/api/ca/v2/role',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

/**
 * 删除一条数据记录
 * @param data
 * @returns {*}
 */
export const deleteRole = (data) => {
    return http({
        url: '/api/ca/v2/role',
        method: 'delete',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

