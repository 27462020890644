<template>
  <div>
    <el-row :gutter="18" style="margin:5px">
      <el-col :span="8">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>个人中心</span>
          </div>
          <div style=" display:flex; justify-content:center">
              <img src="@/assets/img/user.png" alt="" style="height:200px;margin-bottom:5px;">   
           </div>
          <el-row class="userInfo">
            <el-col :span="12"><i class="el-icon-user-solid"></i> 用户名称：</el-col>
            <el-col style="text-align:right" :span="12">{{ username }}</el-col>
          </el-row>
          <el-row class="userInfo">
            <el-col :span="12"><i class="el-icon-phone"></i> 手机号码：</el-col>
            <el-col style="text-align:right" :span="12">{{ phone }}</el-col>
          </el-row>
          <el-row class="userInfo">
            <el-col :span="12"><i class="el-icon-s-promotion"></i> 用户邮箱：</el-col>
            <el-col style="text-align:right" :span="12">{{ email }}</el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card>
          <div slot="header" class="clearfix">
            <span>修改密码</span>
          </div>
          <el-form :model="pwdForm" status-icon :rules="pwdFormRules" ref="pwdForm" label-width="100px">
            <el-form-item label="旧密码" prop="oldPass">
              <el-input placeholder="请输入旧密码" type="password" v-model="pwdForm.oldPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="password">
              <el-input placeholder="请输入新密码" type="password" v-model="pwdForm.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input placeholder="请确认密码" type="password" v-model="pwdForm.checkPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitPwdForm('pwdForm')">提交</el-button>
              <el-button @click="resetPwdForm('pwdForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
   
  </div>
</template>

<script>
// import {changeUserPwd} from '@/api/ca/sys_user';
import {updatePassword} from '@/api/ca/ca_user';
import {getUserName} from "../../lib/user";

export default {
  name: "Personal",
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.pwdForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
   
    return {
      username: "",
      phone: "13000000000",
      email: "13000000@163.com",
      pwdForm: {
        oldPass: '',
        password: '',
        checkPass: ''
      },
      pwdFormRules: {
        oldPass: [
          {required: true,message: '原密码必须填写', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {
            pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,30}$/,
            message: '密码需包含数字、大小写字母、特殊符号，长度为 8 - 30位'
          }
        ],
        checkPass: [
          {required: true, validator: validatePass2, trigger: 'blur'}
        ]
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    // getUserInfo().then(res => {
    //     next(vm => vm.setData(res))
    // })
    next(vm => vm.setData(getUserName()))
  },
  methods: {
    // setData(userinfo){
    //     if(userinfo.isok){
    //         this.username = userinfo.data.username
    //         this.phone = userinfo.data.phone
    //         this.email = userinfo.data.email
    //     }
    // },
    setData(username) {
      this.username = username;
    },
    // 修改密码
    submitPwdForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("确定修改密码么?")
              .then(_ => {
                let params = {
                  oldPassword : this.pwdForm.oldPass,
                  newPassword : this.pwdForm.password
                }
                updatePassword(params)
                  .then(res =>{
                  console.log(res)
                  if(res.code!=0){
                     this.$message.error(res.message)
                  }
                  else{
              
                    this.$message({
                      message: res.message,
                      type: 'success'
                    });
                
                  }
                  }).catch(err => {
                  })
              });
        }
      });
    },
    resetPwdForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style scoped>
.userInfo{
margin: 5px; 
border-top:1px solid #e7eaec; 
padding:11px 0;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>
